import React, { useEffect } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import './App.css';
import { StudentListPage } from './pages/studentListPage';
import { SignIn } from './pages/login/signIn';
import { StudentDetailsEditPage } from './pages/studentDetailsEditPage';
import { HearingAnswersPage } from './pages/hearingAnswersPage';
import { Default } from './common/layout/default';
import { CoursesProvider } from '../src/providers/coursesContext';
import { RoleProvider } from './providers/roleContext';
import { useSignedIn } from '../src/hooks/useSignedIn';

function App(): JSX.Element {
  const { signedIn, isSignedIn } = useSignedIn();

  useEffect(() => {
    signedIn();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Switch>
          <RoleProvider>
            <Route path="/login">{isSignedIn ? <Redirect to="/" /> : <SignIn />}</Route>
            <CoursesProvider>
              <div className="App">
                <Route path="/hearing/answer" exact>
                  <HearingAnswersPage />
                </Route>
                <Switch>
                  <Route path="/" exact>
                    <Default>{isSignedIn ? <StudentListPage /> : <Redirect to="/login" />}</Default>
                  </Route>
                  <Route path="/show/:studentId/edit" exact>
                    <Default>{isSignedIn ? <StudentDetailsEditPage /> : <Redirect to="/login" />}</Default>
                  </Route>
                </Switch>
              </div>
            </CoursesProvider>
          </RoleProvider>
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;

const theme = createMuiTheme({
  typography: {
    fontFamily: ['Noto Sans JP', 'sans-serif'].join(','),
  },
  overrides: {
    MuiOutlinedInput: {
      input: {
        padding: '5.5px 12px',
      },
    },
    MuiTableContainer: {
      root: {
        border: '1px solid #dee2e6',
      },
    },
    MuiTableBody: {
      root: {
        whiteSpace: 'nowrap',
      },
    },
    MuiTableCell: {
      root: {
        padding: 8,
      },
      head: {
        backgroundColor: '#2498b3',
        color: '#ffffff',
        verticalAlign: 'middle',
        textAlign: 'center',
        fontSize: '100%',
        border: '1px solid #dee2e6',
        fontWeight: 'bold',
      },
      body: {
        verticalAlign: 'middle',
        textAlign: 'center',
        fontSize: '100%',
        border: '1px solid #dee2e6',
      },
    },
    MuiDialog: {
      paperWidthSm: {
        maxWidth: '60%',
      },
    },
    MuiDialogContent: {
      root: {
        paddingBottom: '30px',
      },
    },
    MuiButton: {
      root: {
        fontWeight: 500,
        fontSize: '1rem',
        padding: '6px 12px',
      },
    },
  },
  palette: {
    primary: {
      main: '#2498b3',
    },
  },
});
