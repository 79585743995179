import React, { useEffect, useState, memo, VFC, useContext } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { HearingDetailsTable } from '../hearingDetailsTable';
import { Link } from 'react-router-dom';
import { Http } from '../../api/axios';
import { hearingDetails } from '../../types/hearingDetails';
import { DefaultTitle } from '../titles/DefaultTitle';
import { RoleContext } from '../../providers/roleContext';
import { useFetchStudentDetails } from '../../hooks/useFetchStudentDetails';

type Props = {
  isShow: boolean;
  closeDialog: (isShow: boolean) => void;
  studentId: number;
  studentName: string;
};
export const StudentDetailsDialog: VFC<Props> = memo(({ isShow, closeDialog, studentId, studentName }) => {
  const { isAdmin, isStaff, isSpStaff } = useContext(RoleContext);
  const { fetchStudentDetails, studentCourseDetails } = useFetchStudentDetails();

  const [hearingDetails, setHearingDetails] = useState<hearingDetails>();
  useEffect(() => {
    (async () => {
      const res = await Http.get(`/api/${studentId}/hearingDetails`);
      setHearingDetails({ ...res.data });
      await fetchStudentDetails(studentId, isAdmin);
    })();
  }, []);

  const close = () => {
    closeDialog(false);
  };

  return (
    <Dialog open={isShow} onClose={close} fullWidth maxWidth="sm">
      <DialogContent>
        <section>
          <DefaultTitle>{studentName}さん ヒアリング情報</DefaultTitle>
          <Link to={`/show/${studentId}/edit`}>受講者情報詳細ページ</Link>
          <i className="fas fa-caret-down"></i>
        </section>
        <div className="innerBox">
          {hearingDetails && (
            <HearingDetailsTable
              hearingDetails={hearingDetails}
              isAdmin={isAdmin}
              isStaff={isStaff}
              isSpStaff={isSpStaff}
              studentCourseDetails={studentCourseDetails}
            />
          )}
        </div>
      </DialogContent>
      <MuiDialogActions>
        <Button onClick={close} size="large" color="primary">
          閉じる
        </Button>
      </MuiDialogActions>
    </Dialog>
  );
});

StudentDetailsDialog.displayName = 'StudentDetailsDialog';
