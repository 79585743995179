import React, { useEffect, useState, VFC } from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles } from '@material-ui/core/styles';

type Props = {
  updateStudentList: () => void;
};
export const UpdateContainer: VFC<Props> = ({ updateStudentList }) => {
  const classes = useStyles();
  const [isShow, setIsShow] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  const onScroll = () => {
    const innerSearchBox = document.getElementById('innerSearchBox');

    if (
      (innerSearchBox && window.pageYOffset > innerSearchBox.offsetTop) ||
      (window.innerHeight > 1000 && window.pageYOffset > 200)
    ) {
      setIsShow(true);
    } else {
      setIsShow(false);
    }
  };

  return (
    <StudentBoxButton>
      {isShow && (
        <Button
          className={classes.button}
          type="submit"
          variant="contained"
          color="primary"
          onClick={updateStudentList}
        >
          更新する
        </Button>
      )}
    </StudentBoxButton>
  );
};

const StudentBoxButton = styled.div`
  width: 30%;
  margin: auto;
  margin-top: 20px;
  position: sticky;
  bottom: 20px;
`;

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      width: '100%',
      height: 50,
      color: '#fff',
      fontSize: '1rem',
    },
  })
);
