import { getYear } from 'date-fns';
import { course } from '../types/course';
import { CANCELED_CONDITION } from '../types/guideState';
import { SelectMenuItem } from '../types/item';
import { GUIDE_STATE_LIST } from '../constants/index';

export const displayConditionMenuItems: SelectMenuItem[] = [
  {
    item: 'キャンセルを含む',
    value: CANCELED_CONDITION.BOTH,
  },
  {
    item: 'キャンセルを除く',
    value: CANCELED_CONDITION.NOT_CANCELED,
  },
  {
    item: 'キャンセルのみ',
    value: CANCELED_CONDITION.CANCELED,
  },
];

export const genderMenuItems: SelectMenuItem[] = [
  { item: '男性', value: '男性' },
  { item: '女性', value: '女性' },
  { item: 'その他', value: 'その他' },
  { item: '回答しない', value: '回答しない' },
];

export const prefecturesMenuItems: SelectMenuItem[] = [
  { item: 'リモート希望', value: 'リモート希望' },
  { item: '北海道', value: '北海道' },
  { item: '青森県', value: '青森県' },
  { item: '岩手県', value: '岩手県' },
  { item: '宮城県', value: '宮城県' },
  { item: '秋田県', value: '秋田県' },
  { item: '山形県', value: '山形県' },
  { item: '福島県', value: '福島県' },
  { item: '茨城県', value: '茨城県' },
  { item: '栃木県', value: '栃木県' },
  { item: '群馬県', value: '群馬県' },
  { item: '埼玉県', value: '埼玉県' },
  { item: '千葉県', value: '千葉県' },
  { item: '東京都', value: '東京都' },
  { item: '神奈川県', value: '神奈川県' },
  { item: '新潟県', value: '新潟県' },
  { item: '富山県', value: '富山県' },
  { item: '石川県', value: '石川県' },
  { item: '福井県', value: '福井県' },
  { item: '山梨県', value: '山梨県' },
  { item: '長野県', value: '長野県' },
  { item: '岐阜県', value: '岐阜県' },
  { item: '静岡県', value: '静岡県' },
  { item: '愛知県', value: '愛知県' },
  { item: '三重県', value: '三重県' },
  { item: '滋賀県', value: '滋賀県' },
  { item: '京都府', value: '京都府' },
  { item: '大阪府', value: '大阪府' },
  { item: '兵庫県', value: '兵庫県' },
  { item: '奈良県', value: '奈良県' },
  { item: '和歌山県', value: '和歌山県' },
  { item: '鳥取県', value: '鳥取県' },
  { item: '島根県', value: '島根県' },
  { item: '岡山県', value: '岡山県' },
  { item: '広島県', value: '広島県' },
  { item: '山口県', value: '山口県' },
  { item: '徳島県', value: '徳島県' },
  { item: '香川県', value: '香川県' },
  { item: '愛媛県', value: '愛媛県' },
  { item: '高知県', value: '高知県' },
  { item: '福岡県', value: '福岡県' },
  { item: '佐賀県', value: '佐賀県' },
  { item: '長崎県', value: '長崎県' },
  { item: '熊本県', value: '熊本県' },
  { item: '大分県', value: '大分県' },
  { item: '宮崎県', value: '宮崎県' },
  { item: '鹿児島県', value: '鹿児島県' },
  { item: '沖縄県', value: '沖縄県' },
  { item: 'その他', value: 'その他' },
  { item: '回答しない', value: '回答しない' },
];

export const getStartYearMenuItems = (): SelectMenuItem[] => {
  const startYearMenuItems: SelectMenuItem[] = [];

  startYearMenuItems[0] = {
    item: '受講開始年',
    value: '',
  };
  for (let i = 2019; i <= getYear(new Date()) + 1; i++) {
    startYearMenuItems[i] = { item: `${i}`, value: `${i}` };
  }

  return startYearMenuItems;
};

export const getStartMonthMenuItems = (): SelectMenuItem[] => {
  const startMonthMenuItems: SelectMenuItem[] = [];

  startMonthMenuItems[0] = {
    item: '受講開始月',
    value: '',
  };
  for (let i = 1; i <= 12; i++) {
    startMonthMenuItems[i] = { item: `${i}月`, value: `${`0${i}`.slice(-2)}` };
  }

  return startMonthMenuItems;
};

export const getGuideStateMenuItems = (): SelectMenuItem[] => {
  const guideStateMenuItems: SelectMenuItem[] = [];

  guideStateMenuItems[0] = {
    item: '申し込み状況',
    value: '',
  };
  for (let i = 0; i < GUIDE_STATE_LIST.length; i++) {
    guideStateMenuItems[i + 1] = { item: GUIDE_STATE_LIST[i], value: GUIDE_STATE_LIST[i] };
  }
  return guideStateMenuItems;
};

export const getCourseMenuItems = (courses: course[]): SelectMenuItem[] => {
  const courseMenuItems: SelectMenuItem[] = [];

  for (let i = 0; i < courses.length; i++) {
    courseMenuItems[i] = { item: courses[i].courseName, value: courses[i].id };
  }
  return courseMenuItems;
};

export const getAgeMenuItems = (): SelectMenuItem[] => {
  const ageMenuItems: SelectMenuItem[] = [];

  for (let i = 0; i + 15 < 100; i++) {
    ageMenuItems[i] = { item: `${i + 15}`, value: i + 15 };
  }

  return ageMenuItems;
};
