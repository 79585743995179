export const factorItems: string[] = [
  '料金',
  'カリキュラム',
  '講師の質',
  'サポート内容',
  '転職/副業実績',
  '説明会',
  '代表の人柄',
  'その他',
];

export const discoveryItems = {
  広告: ['広告'],
  SNS: ['Twitter', 'Instagram', 'YouTube', 'Facebook'],
  その他: ['公式LINE', '知り合い', 'ブログ', 'その他'],
};
