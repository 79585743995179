import React, { VFC } from 'react';
import styled from 'styled-components';
import { useSignedIn } from '../../hooks/useSignedIn';

export const Header: VFC = () => {
  const { signOut } = useSignedIn();
  const handleSubmit = () => {
    signOut();
  };

  return (
    <AppHeader>
      <Container>
        <HeaderInner>
          <Logo src={`${process.env.PUBLIC_URL}/assets/logo-color.png`} alt="ロゴ" />
          <div>
            <Logout onClick={handleSubmit}>
              <i className="fas fa-sign-out-alt"></i>
              ログアウト
            </Logout>
          </div>
        </HeaderInner>
      </Container>
    </AppHeader>
  );
};

const AppHeader = styled.header`
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #2498b3;
`;

const Container = styled.div`
  padding-right: 15px;
  padding-left: 15px;
`;

const Logo = styled.img`
  height: 40px;
`;

const HeaderInner = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Logout = styled.a`
  color: #fff;
  display: inline-flex;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    color: #007bff;
  }
`;
