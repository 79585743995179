import React, { memo, VFC } from 'react';
import { createStyles, makeStyles, MenuItem, Select } from '@material-ui/core';
import { ControllerRenderProps } from 'react-hook-form';
import { SelectMenuItem } from '../../types/item';

type Props = {
  displayEmpty?: boolean | undefined;
  menuItems: SelectMenuItem[];
  field?: ControllerRenderProps<any, string>;
  name?: string;
  defaultValue?: any;
  handleChange?: (value: any, event?: any) => void;
  value?: any;
  styles?: { width?: number; display?: string; height?: number };
  error?: boolean;
};
const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 8;
const menuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};
export const DefaultSelectBox: VFC<Props> = memo((props) => {
  const classes = useStyles(props.styles ? props.styles : {});

  return (
    <Select
      MenuProps={menuProps}
      className={classes.select}
      displayEmpty={props.displayEmpty || true}
      {...props.field}
      name={props.name}
      defaultValue={props.defaultValue}
      onChange={(e) =>
        props.handleChange ? props.handleChange(props.value || e, e) : props.field?.onChange(props.value || e, e)
      }
      error={props.error}
    >
      {props.menuItems.map((menu) => (
        <MenuItem key={`${menu.item} + ${menu.value}`} value={menu.value}>
          {menu.item}
        </MenuItem>
      ))}
    </Select>
  );
});

const useStyles = makeStyles(() =>
  createStyles({
    select: (styles: { width?: number; display?: string; height?: number }) => ({
      backgroundColor: '#ffffff',
      height: `${styles.height ? styles.height : 30}px`,
      width: `${styles.width ? styles.width : 100}%`,
      padding: 10,
      border: '1px solid #dee2e6',
      textAlign: 'left',
      display: `${styles.display}`,
    }),
  })
);

DefaultSelectBox.displayName = 'DefaultSelectBox';
