import { useCallback, useState } from 'react';
import { Http } from '../api/axios';
import { studentList } from '../types/studentList';
import { searchCondition } from '../types/searchCondition';
import { studentInfoForBusiness } from '../types/studentInfoForBusiness';
import { isAutoTrial, isTrial } from '../utils/student';
import { startOfMonth, addMonths, addDays, isAfter, parse } from 'date-fns';

export const useFetchStudentList = () => {
  const [loadingFlag, setLoadingFlag] = useState<boolean>(false);
  const [studentList, setStudentList] = useState<studentList[]>([]);
  const [studentListForBusiness, setStudentListForBusiness] = useState<studentInfoForBusiness[]>([]);
  const [trialStudents, setTrialStudents] = useState<studentList[]>([]);

  const fetchStudentList = useCallback(
    async (searchCondition: searchCondition, isAdmin: boolean, isSpStaff: boolean) => {
      sessionStorage.setItem('searchCondition', JSON.stringify(searchCondition));
      setLoadingFlag(true);

      if (isAdmin || isSpStaff) {
        const res = await Http.post('/api/studentList/search', searchCondition);
        setStudentList(res.data);

        // トライアル受講生のみをフィルタリングして返す
        const trialFiltered = res.data.filter((student: studentList) => {
          // キャンセルはトライアル表示対象外
          const guideState = student.studentCourseDetailsList[0]?.guideState;
          if (guideState.canceled) return false;
          // 受け放題コースによって追加されたコースの場合はトライアル表示対象外
          const additionalCourse = student.studentCourseDetailsList[0]?.studentCourse.remark?.includes(
            '受け放題コースでの追加'
          );
          if (additionalCourse) return false;
          // 仮申し込みの場合はトライアル表示対象外
          if (guideState.guideState === '仮申し込み') return false;
          // Discordに参加した日を基準としたトライアル期間判定
          const requestOn = new Date(student.studentCourseDetailsList[0]?.studentCourse?.requestOn);
          const startTrialDate = startOfMonth(addMonths(requestOn, 2));
          const expirationDate = addDays(startTrialDate, 14);
          const invitedOn = student.studentCourseDetailsList[0]?.studentCourse?.invitedOn;
          if (invitedOn) {
            const parsedInvitedOn = parse(invitedOn, 'yyyy-MM-dd', new Date());

            if (isAfter(parsedInvitedOn, expirationDate)) {
              return false;
            } else if (isAfter(startTrialDate, parsedInvitedOn)) {
              return isTrial(parsedInvitedOn);
            } else if (isAutoTrial(startTrialDate, expirationDate)) {
              return isTrial(parsedInvitedOn);
            }
          } else {
            // Discordに参加せずに申し込みから翌々月になった場合は下記で判定
            return isAutoTrial(startTrialDate, expirationDate);
          }
        });
        setTrialStudents(trialFiltered);
      } else {
        const res = await Http.post('/api/studentList/studentInfoForBusiness', searchCondition);
        setStudentListForBusiness(res.data);
      }
      setLoadingFlag(false);
    },
    []
  );

  return { fetchStudentList, loadingFlag, studentList, trialStudents, studentListForBusiness };
};
