import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import React, { memo, VFC } from 'react';
import { ControllerRenderProps } from 'react-hook-form';

type RadioItems = {
  item: string;
  value: string | number | boolean;
}[];
type Props = {
  radioItems: RadioItems;
  field?: ControllerRenderProps<any, any>;
  value?: any;
};
export const DefaultRadio: VFC<Props> = memo((props: Props) => {
  return (
    <RadioGroup row {...props.field}>
      {props.radioItems.map((radio) => (
        <FormControlLabel
          key={`${radio.item + radio.value}`}
          value={radio.value}
          control={<Radio color="primary" />}
          label={radio.item}
          checked={radio.value === props.field?.value || radio.value.toString() === props.field?.value}
        />
      ))}
    </RadioGroup>
  );
});
DefaultRadio.displayName = 'DefaultRadio';
