import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_API_URL;

export const Http = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: process.env.REACT_APP_AUTHORIZATION,
  },
  responseType: 'json',
});
