import React, { memo, VFC } from 'react';
import styled from 'styled-components';
import { hearingDetails } from '../types/hearingDetails';
import { device } from '../style/device';
import { studentCourseDetails } from '../types/studentCourseDetails';
import { urlify } from '../utils/url';

type Props = {
  hearingDetails: hearingDetails;
  isAdmin: boolean;
  isStaff?: boolean;
  isSpStaff?: boolean;
  studentCourseDetails?: studentCourseDetails[];
};
export const HearingDetailsTable: VFC<Props> = memo(
  ({ hearingDetails, isAdmin, isStaff, isSpStaff, studentCourseDetails }) => {
    const remarkWithLinks =
      studentCourseDetails && studentCourseDetails.length > 0
        ? urlify(studentCourseDetails[0].studentCourse.remark)
        : '';

    return (
      <div>
        {(isAdmin || isStaff || isSpStaff) && (
          <Dl>
            <Dt>備考欄</Dt>
            <Dd dangerouslySetInnerHTML={{ __html: remarkWithLinks }}></Dd>
          </Dl>
        )}
        <Dl>
          <Dt>Q1.今の年齢と性別を教えてください。</Dt>
          <Dd>年齢：{hearingDetails.hearing.age}</Dd>
          <Dd>性別：{hearingDetails.hearing.gender}</Dd>
        </Dl>
        <Dl>
          <Dt>Q2. IT業界の経験を教えてください。</Dt>
          <Dd>{hearingDetails.hearing.experience}</Dd>
        </Dl>
        <Dl>
          <Dt>Q3.現在の状況を教えてください。</Dt>
          <Dd>{hearingDetails.hearing.job}</Dd>
        </Dl>
        <Dl>
          <Dt>Q4.希望する働き方・勤務地を教えてください。</Dt>
          <Dd>働き方：{hearingDetails.hopeSupport.workStyle}</Dd>
          <Dd>勤務地：{hearingDetails.hopeSupport.workPlace}</Dd>
        </Dl>
        {isAdmin && (
          <>
            <Dl>
              <Dt>Q5.RaiseTechを知ったきっかけを教えてください。</Dt>
              <Dd>
                {hearingDetails.hearing.discovery}
                {hearingDetails.hearing.otherDiscovery.length > 0 && (
                  <>
                    <hr />
                    その他の詳細：
                    {hearingDetails.hearing.otherDiscovery}
                  </>
                )}
              </Dd>
            </Dl>
            <Dl>
              <Dt>Q6.受講の決め手を教えてください。</Dt>
              <Dd>
                {hearingDetails.hearing.factor}
                {hearingDetails.hearing.otherFactor.length > 0 && (
                  <>
                    <hr />
                    その他の詳細：
                    {hearingDetails.hearing.otherFactor}
                  </>
                )}
              </Dd>
            </Dl>
            <Dl>
              <Dt>
                Q7.最後に、無料説明会に参加されたかどうか教えてください。動画視聴の場合も「参加した」にチェックを入れてください。
              </Dt>
              <Dd>{hearingDetails.hearing.briefing.toString() === 'true' ? '参加した' : '参加していない'}</Dd>
            </Dl>
          </>
        )}
      </div>
    );
  }
);

const Dl = styled.dl`
  padding-bottom: 30px;
`;
const Dt = styled.dt`
  background-color: #2498b3;
  color: #fff;
  padding: 20px;
  font-weight: 700;
  line-height: 25px;

  @media ${device.mobile} {
    padding: 10px;
  }
`;
const Dd = styled.dd`
  background-color: #fff;
  padding: 20px;
  margin-bottom: 0px;
  margin-left: 0;
  white-space: break-spaces;
  line-height: initial;
  word-wrap: break-word;
`;

HearingDetailsTable.displayName = 'HearingDetailsTable';
