import React, { memo, useContext, useEffect, useMemo, useState, VFC } from 'react';
import styled from 'styled-components';
import { CsvButton } from './csvButton';
import { searchCondition } from '../../types/searchCondition';
import { CoursesContext } from '../../providers/coursesContext';
import { DefaultSelectBox } from '../../common/selectBox/defaultSelectBox';
import { DefaultCheckbox } from '../../common/checkbox/defaultCheckbox';
import {
  Controller,
  UseFormHandleSubmit,
  UseFormGetValues,
  UseFormSetValue,
  Control,
  UseFormReset,
  DeepMap,
  FieldError,
} from 'react-hook-form';
import { DefaultTextField } from '../../common/textField/defaultTextField';
import { DefaultButton } from '../../common/buttons/defaultButton';
import { getGuideStateMenuItems, getStartMonthMenuItems, getStartYearMenuItems } from '../../common/selectMenuItems';
import { searchCouponRadioItems } from '../../common/radioItems';
import { DefaultRadio } from '../../common/radio/defaultRadio';
import { SelectMenuItem } from '../../types/item';
import { format, isAfter, isValid } from 'date-fns';
import { Box, Grid, Switch } from '@material-ui/core';
import { DefaultDatePicker } from '../../common/datepicker/defaultDatePicker';
import { COUPON_CONDITION } from '../../types/coupon';
import { RoleContext } from '../../providers/roleContext';

type Props = {
  search: (searchCondition: searchCondition) => void;
  RHFhandleSubmit: UseFormHandleSubmit<searchCondition>;
  getValues: UseFormGetValues<searchCondition>;
  setValue: UseFormSetValue<searchCondition>;
  control: Control<searchCondition>;
  reset: UseFormReset<searchCondition>;
  errors: DeepMap<searchCondition, FieldError>;
};

type MenuItems = {
  startYear: SelectMenuItem[];
  startMonth: SelectMenuItem[];
  guideState: SelectMenuItem[];
};

const SearchFormComponent: VFC<Props> = ({ search, RHFhandleSubmit, getValues, setValue, control, reset, errors }) => {
  const { courses } = useContext(CoursesContext);
  const { isAdmin } = useContext(RoleContext);
  const today = new Date();

  const [minEndInvitedOn, setMinEndInvitedOn] = useState<Date>();
  const [invitedOnChecked, setInvitedOnChecked] = useState<boolean>(false);

  const validInvitedOn = {
    startInvitedOn: {
      validate: (value: string) => {
        return value === '' || isValid(new Date(value)) || 'この日付は無効です。';
      },
    },
    endInvitedOn: {
      validate: (value: string) => {
        if (value === '') {
          return true;
        }
        if (!isValid(new Date(value))) {
          return 'この日付は無効です。';
        }
        if (isAfter(new Date(getValues('startInvitedOn')), new Date(value))) {
          return '開始日以降の日付を選択してください。';
        }
        return true;
      },
    },
  };

  const menuItems = useMemo((): MenuItems => {
    return {
      startYear: getStartYearMenuItems(),
      startMonth: getStartMonthMenuItems(),
      guideState: getGuideStateMenuItems(),
    };
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem('searchCondition')) {
      const searchCondition: searchCondition = JSON.parse(sessionStorage.getItem('searchCondition') || '');
      if (searchCondition.startInvitedOn !== '' || searchCondition.endInvitedOn !== '') {
        setInvitedOnChecked(true);
      }
      reset(searchCondition);
    }
  }, []);

  const onSubmit = RHFhandleSubmit(async (data) => {
    const searchCondition: searchCondition = JSON.parse(sessionStorage.getItem('searchCondition') || '');
    data.order = searchCondition.order;
    data.orderDirection = searchCondition.orderDirection;
    search(data);
  });

  const isCheckedCourseId = (courseId: number): boolean => {
    return getValues('courseIds') ? getValues('courseIds').includes(courseId) : false;
  };

  const handleChange = (courseId: number) => {
    const { courseIds: ids } = getValues();
    const newParams = ids?.includes(courseId) ? ids?.filter((id) => id !== courseId) : [...(ids ?? []), courseId];
    setValue('courseIds', newParams, { shouldValidate: true });
  };

  const handleChangeForSwitch = () => {
    if (invitedOnChecked) {
      // 招待日範囲検索を無効にする場合、日付をリセットする
      setValue('startInvitedOn', '');
      setValue('endInvitedOn', '');
    } else {
      // 招待日範囲検索を有効にする場合、案内状況をリセットする(招待日が有効な案内状況が限られるため)
      setValue('guideState', '');
    }
    setInvitedOnChecked(!invitedOnChecked);
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <SearchFormBox>
          <Controller
            control={control}
            name="nameOrEmail"
            render={({ field }) => (
              <DefaultTextField
                label={
                  isAdmin
                    ? '名前/メールアドレス/Discord名/Discord IDを入力してください。'
                    : '名前/Discord名/Discord IDを入力してください。'
                }
                variant="standard"
                field={field}
                width={70}
              />
            )}
          />
        </SearchFormBox>
        <SearchFormBox>
          <SearchFormBoxDate>
            <SearchFormBoxSelect>
              <Controller
                control={control}
                name="startYear"
                render={({ field }) => <DefaultSelectBox menuItems={menuItems.startYear} field={field} />}
              />
            </SearchFormBoxSelect>
          </SearchFormBoxDate>
          <SearchFormBoxDate>
            <SearchFormBoxSelect>
              <Controller
                control={control}
                name="startMonth"
                render={({ field }) => <DefaultSelectBox menuItems={menuItems.startMonth} field={field} />}
              />
            </SearchFormBoxSelect>
          </SearchFormBoxDate>
          {isAdmin && (
            <SearchFormBoxDate>
              <SearchFormBoxSelect>
                <Controller
                  control={control}
                  name="guideState"
                  render={({ field }) => <DefaultSelectBox menuItems={menuItems.guideState} field={field} />}
                />
              </SearchFormBoxSelect>
            </SearchFormBoxDate>
          )}
        </SearchFormBox>
        <SearchFormBox>
          <Box display="flex" bgcolor="#fff" padding={2}>
            <Grid container direction="column" justify="center">
              <Grid item xs={2}>
                <i className="fa fa-clone main-color" />
                受講コース
              </Grid>
              <Grid item xs={10}>
                {courses.map((course) => (
                  <Controller
                    key={course.courseName}
                    control={control}
                    name="courseIds"
                    render={() => (
                      <DefaultCheckbox
                        value={course.id}
                        key={`course[${course.id}]`}
                        label={course.courseName}
                        checked={isCheckedCourseId(course.id)}
                        handleChange={handleChange}
                      />
                    )}
                  />
                ))}
              </Grid>
            </Grid>
          </Box>
        </SearchFormBox>
        <Box display="flex" gridGap={48}>
          {isAdmin && (
            <SearchFormBox>
              <SearchFormBoxRadio>
                <SearchFormBoxCheckTitle>
                  <i className="fa fa-sticky-note main-color" />
                  クーポン適用
                </SearchFormBoxCheckTitle>
                <div>
                  <Controller
                    control={control}
                    name="couponCondition"
                    render={({ field }) => (
                      <>
                        <DefaultRadio radioItems={searchCouponRadioItems} field={field} />
                        {getValues('couponCondition') == COUPON_CONDITION.APPLICABLE && (
                          <Controller
                            control={control}
                            name="couponCode"
                            render={({ field }) => (
                              <DefaultTextField label="クーポン詳細検索" variant="standard" field={field} width={100} />
                            )}
                          />
                        )}
                      </>
                    )}
                  />
                </div>
              </SearchFormBoxRadio>
            </SearchFormBox>
          )}
          <SearchFormBoxDateRange>
            <SearchFormBoxDateRangeTitle>
              <i className="fa fa-solid fa-pen-square main-color fa-lg" />
              受け放題コース表示
              <Controller
                control={control}
                name="displayUnlimitedCourse"
                render={({ field }) => <Switch checked={field.value} onChange={field.onChange} color="primary" />}
              />
            </SearchFormBoxDateRangeTitle>
          </SearchFormBoxDateRange>
        </Box>
        {isAdmin && (
          <>
            <SearchFormBoxDateRange>
              <SearchFormBoxDateRangeTitle>
                <i className="fa fa-solid fa-user-tag main-color" />
                Slack・Discord招待日
                <Switch
                  checked={invitedOnChecked}
                  onChange={handleChangeForSwitch}
                  color="primary"
                  name="invitedOnChecked"
                />
              </SearchFormBoxDateRangeTitle>
              {invitedOnChecked && (
                <DateRange>
                  <Controller
                    control={control}
                    name="startInvitedOn"
                    rules={validInvitedOn.startInvitedOn}
                    render={({ field }) => (
                      <DefaultDatePicker
                        label="招待日(開始日)"
                        field={field}
                        helperText={errors.startInvitedOn?.message || ''}
                        maxDate={today}
                        error={errors.startInvitedOn != null}
                        onChange={(date) => {
                          setMinEndInvitedOn(date);
                          field.onChange(isValid(date) ? format(date, 'yyyy-MM-dd') : date || '');
                        }}
                      />
                    )}
                  />
                  〜
                  <Controller
                    control={control}
                    name="endInvitedOn"
                    rules={validInvitedOn.endInvitedOn}
                    render={({ field }) => (
                      <DefaultDatePicker
                        label="招待日(終了日)"
                        field={field}
                        helperText={errors.endInvitedOn?.message || ''}
                        maxDate={today}
                        minDate={isValid(minEndInvitedOn) ? minEndInvitedOn : undefined}
                        error={errors.endInvitedOn != null}
                        onChange={(date) => {
                          field.onChange(isValid(date) ? format(date, 'yyyy-MM-dd') : date || '');
                        }}
                      />
                    )}
                  />
                </DateRange>
              )}
            </SearchFormBoxDateRange>
            <Box display="flex" gridGap={1} maxWidth="240px" bgcolor="#fff" mb="40px" p="12px">
              <SearchFormBoxDateRangeTitle>
                <i className="fa fa-clone main-color" />
                トライアル受講生のみ
              </SearchFormBoxDateRangeTitle>
              <Controller
                control={control}
                name="isTrial"
                render={({ field }) => <Switch checked={field.value} onChange={field.onChange} color="primary" />}
              />
            </Box>
          </>
        )}
        <SearchFormBox>
          {isAdmin && (
            <Controller
              control={control}
              name="campaignName"
              render={({ field }) => (
                <DefaultTextField
                  label="キャンペーン内容を入力してください。（例：サロン/アフィリ/学割）"
                  variant="standard"
                  field={field}
                  width={70}
                />
              )}
            />
          )}
          <SearchFormBoxButton>
            <DefaultButton type="submit" width={isAdmin ? 70 : 100}>
              <i aria-hidden="true" className="fa fa-search"></i>検索
            </DefaultButton>
          </SearchFormBoxButton>
        </SearchFormBox>
      </form>
      <CsvButton getValues={getValues} />
    </>
  );
};

SearchFormComponent.displayName = 'SearchFormComponent';

export const SearchForm = memo(SearchFormComponent);

const SearchFormBox = styled.div`
  margin-bottom: 40px;
  display: flex;
`;

const SearchFormBoxDate = styled.div`
  width: 70%;
`;

const SearchFormBoxSelect = styled.div`
  width: 75%;
`;

const SearchFormBoxRadio = styled.div`
  background-color: #fff;
  border: 1px solid #dee2e6;
  padding: 0.75rem;
  display: flex;
`;

const SearchFormBoxCheckTitle = styled.div`
  width: 150px;
  margin: auto;
`;

const SearchFormBoxButton = styled.div`
  text-align: center;
  width: 30%;
`;

const SearchFormBoxDateRange = styled.div`
  background-color: #fff;
  margin-bottom: 40px;
  align-items: baseline;
  padding: 12px;
  display: inline-block;
  gap: 1.5rem;
`;

const SearchFormBoxDateRangeTitle = styled.div`
  display: flex;
  align-items: center;
  margin: auto;
`;

const DateRange = styled.div`
  align-items: baseline;
  display: flex;
  gap: 1.5rem;
`;
