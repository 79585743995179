import React, { memo, VFC } from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';

type Props = {
  key?: string | number;
  label?: string;
  value: string | number | boolean;
  checked?: boolean;
  handleChange: (value: any, event?: any) => void;
};
export const DefaultCheckbox: VFC<Props> = memo((props) => {
  return (
    <FormControlLabel
      key={props.key}
      label={props.label}
      control={
        <Checkbox color="primary" checked={props.checked} onChange={(e) => props.handleChange(props.value || e, e)} />
      }
    />
  );
});

DefaultCheckbox.displayName = 'DefaultCheckbox';
