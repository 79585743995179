import React, { useEffect, useState, useContext, memo, VFC } from 'react';
import styled from 'styled-components';
import TableBody from '@material-ui/core/TableBody';
import { Table } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { CoursesContext } from '../../providers/coursesContext';
import { GUIDE_STATE_LIST } from '../../constants';

type applicationStatusThisMonth = {
  [key: string]: number[];
};

type applicationStatusPastMonth = {
  month: number;
  count: number;
};

type Props = {
  applicationStatusThisMonth: applicationStatusThisMonth;
  numberOfApplicationsLastMonth: applicationStatusPastMonth;
  numberOfApplicationsMonthBeforeLast: applicationStatusPastMonth;
};
export const ApplicationStatusThisMonth: VFC<Props> = memo(
  ({ applicationStatusThisMonth, numberOfApplicationsLastMonth, numberOfApplicationsMonthBeforeLast }) => {
    const { courses } = useContext(CoursesContext);
    const [numberOfApplicationsListPerGuideState, setNumberOfApplicationsListPerGuideState] = useState<number[]>([]);
    const [numberOfApplicationsListPerCourse, setNumberOfApplicationsListPerCourse] = useState<number[]>([]);
    const [totalNumberOfApplications, setTotalNumberOfApplications] = useState<number>(0);
    // 単体コースは除く
    const removedCourses = [1, 3];
    const targetCourses = courses.filter((course) => !removedCourses.includes(course.id));

    useEffect(() => {
      const createListOfNumberOfApplicationsPerGuideState = (
        applicationStatusThisMonth: applicationStatusThisMonth
      ) => {
        const newTotalNumberOfApplicationsPerGuideState: number[] = GUIDE_STATE_LIST.map((guideState) => {
          return applicationStatusThisMonth[guideState]
            ? applicationStatusThisMonth[guideState].reduce((sum: number, current: number) => sum + current, 0)
            : 0;
        });
        setNumberOfApplicationsListPerGuideState(newTotalNumberOfApplicationsPerGuideState);
      };

      const createListOfNumberOfApplicationsPerCourse = (applicationStatusThisMonth: applicationStatusThisMonth) => {
        const newTotalNumberOfApplicationsPerCourse: number[] = [];

        targetCourses.map((course) => {
          let total = 0;
          Object.keys(applicationStatusThisMonth).forEach((guideState) => {
            total += applicationStatusThisMonth[guideState][course.id - 1];
          });
          newTotalNumberOfApplicationsPerCourse.push(total);
        });
        setNumberOfApplicationsListPerCourse(newTotalNumberOfApplicationsPerCourse);
      };

      createListOfNumberOfApplicationsPerGuideState(applicationStatusThisMonth);
      createListOfNumberOfApplicationsPerCourse(applicationStatusThisMonth);
    }, [applicationStatusThisMonth]);

    useEffect(() => {
      const calculateTotalNumberOfApplications = () => {
        if (numberOfApplicationsListPerGuideState.length == 0) return;
        const total = numberOfApplicationsListPerGuideState.reduce((sum, current) => {
          return sum + current;
        });
        setTotalNumberOfApplications(total);
      };

      calculateTotalNumberOfApplications();
    }, [numberOfApplicationsListPerGuideState]);

    return (
      <NumberOfApplicationsBox>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                {targetCourses.map((course) => (
                  <TableCell key={course.id}>{course.courseName}</TableCell>
                ))}
                <TableCell>合計</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {GUIDE_STATE_LIST.map((guideState, i) => (
                <TableRow key={guideState}>
                  <TableHeader>{guideState}</TableHeader>
                  {targetCourses.map((course) =>
                    applicationStatusThisMonth[guideState] ? (
                      <TableCell key={course.id}>{applicationStatusThisMonth[guideState][course.id - 1]}</TableCell>
                    ) : (
                      <TableCell key={course.id}>0</TableCell>
                    )
                  )}
                  <TableCell>{numberOfApplicationsListPerGuideState[i]}</TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableHeader>合計</TableHeader>
                {numberOfApplicationsListPerCourse.map((count, key) => (
                  <TableCell key={key}>{count}</TableCell>
                ))}
                <TableCell>{totalNumberOfApplications}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Span>
          {numberOfApplicationsMonthBeforeLast.month}月の申し込み人数…
          {numberOfApplicationsMonthBeforeLast.count}人
        </Span>
        <Span>
          {numberOfApplicationsLastMonth.month}月の申し込み人数…{numberOfApplicationsLastMonth.count}人
        </Span>
      </NumberOfApplicationsBox>
    );
  }
);

const TableHeader = styled(TableCell)`
  color: #ffffff !important;
  background-color: #2498b3;
`;

const NumberOfApplicationsBox = styled.div`
  overflow: scroll;
  padding: 30px;
  background-color: #ffffff;
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.1);
`;

const Span = styled.span`
  margin-right: 10px;
`;

ApplicationStatusThisMonth.displayName = 'ApplicationStatusThisMonth';
