import React, { memo, VFC } from 'react';
import {
  createMuiTheme,
  createStyles,
  FilledInputProps,
  InputProps,
  makeStyles,
  MuiThemeProvider,
  OutlinedInputProps,
  TextField,
} from '@material-ui/core';
import { ControllerRenderProps } from 'react-hook-form';

type Props = {
  type?: string;
  label?: string;
  helperText?: string;
  variant?: 'filled' | 'standard' | 'outlined' | undefined;
  field: ControllerRenderProps<any, string>;
  width?: number;
  InputProps?: Partial<InputProps> | Partial<FilledInputProps> | Partial<OutlinedInputProps>;
  multiline?: boolean;
  rows?: number;
  disabled?: boolean;
};
export const DefaultTextField: VFC<Props> = memo((props) => {
  const classes = useStyles(props.width || 100);

  return (
    <MuiThemeProvider theme={theme}>
      <TextField
        className={classes.textField}
        type={props.type || 'text'}
        label={props.label}
        error={props.helperText ? props.helperText.length > 0 : false}
        helperText={props.helperText}
        variant={props.variant || 'outlined'}
        {...props.field}
        value={props.field.value}
        fullWidth
        InputProps={props.InputProps}
        multiline={props.multiline || false}
        rows={props.rows}
        disabled={props.disabled || false}
      />
    </MuiThemeProvider>
  );
});

const useStyles = makeStyles(() =>
  createStyles({
    textField: (width) => ({
      width: `${width}%`,
      backgroundColor: '#ffffff',
      display: 'block',
    }),
  })
);

const theme = createMuiTheme({
  overrides: {
    MuiOutlinedInput: {
      input: {
        padding: '12px',
      },
    },
  },
  palette: {
    primary: {
      main: '#2498b3',
    },
  },
});

DefaultTextField.displayName = 'DefaultTextField';
