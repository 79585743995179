import React, { VFC } from 'react';
import styled from 'styled-components';
import { LoginForm } from './loginForm';

export const SignIn: VFC = () => {
  return (
    <Container>
      <LoginTitle>
        <LoginTitleImg src={`${process.env.PUBLIC_URL}/assets/logo-color.png`} alt="ロゴ" />
      </LoginTitle>
      <LoginBox>
        <LoginForm />
      </LoginBox>
    </Container>
  );
};

const Container = styled.main`
  background-color: #e9eaed;
  padding: 8% 0 0;
  margin: auto;
  height: 100%;
`;

const LoginTitle = styled.h1`
  text-align: center;
  margin-bottom: 30px;
`;

const LoginTitleImg = styled.img`
  width: 200px;
`;

const LoginBox = styled.div`
  width: 400px;
  background-color: #fff;
  padding: 30px;
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.1);
  margin: auto;
  max-width: 720px;
`;
