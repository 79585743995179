import React, { ReactNode, VFC } from 'react';
import { Header } from '../header/header';
import { Footer } from '../footer/footer';
import { PageTop } from '../pageTop/pageTop';

type Props = { children: ReactNode };
export const Default: VFC<Props> = ({ children }) => {
  return (
    <>
      <Header />
      {children}
      <Footer />
      <PageTop />
    </>
  );
};
