import React, { useContext, VFC } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Box, TableContainer } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import { Table } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { student } from '../../types/student';
import { studentCourseDetails } from '../../types/studentCourseDetails';
import { RoleContext } from '../../providers/roleContext';
import { urlify } from '../../utils/url';

type StudentCoursesProps = {
  student: student;
  studentCourseDetails: studentCourseDetails[];
};

export const StudentCourses: VFC<StudentCoursesProps> = ({ student, studentCourseDetails }) => {
  const classes = useStyles();
  const { isStaff, isSpStaff } = useContext(RoleContext);

  if (!studentCourseDetails || studentCourseDetails.length === 0) return null;

  const remarkWithLinks =
    studentCourseDetails && studentCourseDetails.length > 0 ? urlify(studentCourseDetails[0].studentCourse.remark) : '';

  return (
    <div>
      <TableContainer className={classes.root}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className={classes.head} component="th" scope="row">
                No
              </TableCell>
              <TableCell className={classes.body} component="td" scope="row">
                {student.id}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.head} component="th" scope="row">
                お名前
              </TableCell>
              <TableCell className={classes.body} component="td" scope="row">
                {student.name}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.head} component="th" scope="row">
                お名前(カナ)
              </TableCell>
              <TableCell className={classes.body} component="td" scope="row">
                {student.kname}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer className={classes.root}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className={classes.head} component="th" scope="row">
                受講コース
              </TableCell>
              <TableCell className={classes.body} component="td" scope="row">
                {studentCourseDetails[0].course.courseName}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.head} component="th" scope="row">
                申込日
              </TableCell>
              <TableCell className={classes.body} component="td" scope="row">
                {studentCourseDetails[0].studentCourse.requestOn}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.head} component="th" scope="row">
                希望受講開始時期
              </TableCell>
              <TableCell className={classes.body} component="td" scope="row">
                {studentCourseDetails[0].studentCourse.startOn}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.head} component="th" scope="row">
                決済日
              </TableCell>
              <TableCell className={classes.body} component="td" scope="row">
                {studentCourseDetails[0].studentCourse.settlementOn}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.head} component="th" scope="row">
                Slack
                <br />
                Discord招待日
              </TableCell>
              <TableCell className={classes.body} component="td" scope="row">
                {studentCourseDetails[0].studentCourse.invitedOn}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.head} component="th" scope="row">
                申し込み状況
              </TableCell>
              <TableCell className={classes.body} component="td" scope="row">
                {studentCourseDetails[0].guideState.guideState}
              </TableCell>
            </TableRow>
            {studentCourseDetails[0].studentCourse.unlimitedCourseId !== 1 && (
              <TableRow>
                <TableCell className={classes.head} component="th" scope="row">
                  受け放題
                </TableCell>
                <TableCell className={classes.body} component="td" scope="row">
                  {studentCourseDetails[0]?.unlimitedCourse.unlimitedCourseName}
                </TableCell>
              </TableRow>
            )}
            {(isStaff || isSpStaff) && (
              <TableRow>
                <TableCell className={classes.head} component="th" scope="row">
                  備考欄
                </TableCell>
                <TableCell className={classes.body} component="td" scope="row">
                  <Box whiteSpace="pre-wrap" dangerouslySetInnerHTML={{ __html: remarkWithLinks }}></Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    head: {
      backgroundColor: '#2498b3',
      color: '#ffffff',
      verticalAlign: 'middle',
      textAlign: 'left',
      fontSize: '100%',
      border: '1px solid #dee2e6',
      width: '200px',
      height: '35px',
      fontWeight: 'bold',
    },
    body: {
      verticalAlign: 'middle',
      textAlign: 'left',
      fontSize: '100%',
      border: '1px solid #dee2e6',
      width: '80%',
      backgroundColor: '#ffffff',
    },
    root: {
      marginBottom: '25px',
    },
  })
);
