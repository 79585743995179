import React, { memo, VFC } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { DefaultButton } from '../buttons/defaultButton';
import styled from 'styled-components';

type Props = {
  studentCourseId: number;
  studentName: string;
  isShowDeleteDialog: boolean;
  closeDeleteDialog: (isShowDeleteDialog: boolean) => void;
  deleteStudentCourseDetails: (studentCourseId: number) => void;
};
export const DeleteStudentDetailsDialog: VFC<Props> = memo(
  ({ studentCourseId, studentName, isShowDeleteDialog, closeDeleteDialog, deleteStudentCourseDetails }) => {
    const close = () => {
      closeDeleteDialog(false);
    };
    const deleteStudent = () => {
      deleteStudentCourseDetails(studentCourseId);
      close();
    };

    return (
      <Dialog open={isShowDeleteDialog} onClose={close}>
        <DeleteDialogContent>
          <DialogContent>
            <IconStyle className={`fas fa-exclamation-triangle`} />
            <Confirmation>
              id: {studentCourseId} {studentName}
              <br />
              を削除しますか？
            </Confirmation>
            <DefaultButton onClick={deleteStudent}>削除</DefaultButton>
          </DialogContent>
        </DeleteDialogContent>
      </Dialog>
    );
  }
);
const DeleteDialogContent = styled.div`
  text-align: center;
`;

const IconStyle = styled.h1`
  color: #2498b3;
  font-size: 3em;
  margin-bottom: 20px;
`;
const Confirmation = styled.div`
  margin-bottom: 20px;
`;

DeleteStudentDetailsDialog.displayName = 'DeleteStudentDetailsDialog';
