import { addDays, isAfter, isWithinInterval } from 'date-fns';

// トライアル期間判定
export const isTrial = (invitedOn: Date): boolean => {
  // トライアル期間終了の日付
  const expirationDate = addDays(invitedOn, 14);

  // トライアル期間終了の日付と現在の日付を比較
  // 第1引数の日付が第２引数の日付以降の場合trueを返す
  return isAfter(expirationDate, new Date());
};

// 強制トライアルの判定
export const isAutoTrial = (startTrialDate: Date, expirationDate: Date): boolean => {
  // 現在の日付が強制トライアル期間内に存在するかを確認
  return isWithinInterval(new Date(), { start: startTrialDate, end: expirationDate });
};
