import React, { useEffect, useState, VFC } from 'react';
import styled from 'styled-components';
import { Box } from '@material-ui/core';

export const StudentCard: VFC<{ imgSrc: string }> = ({ imgSrc }) => {
  const [isImg, setIsImg] = useState<boolean>(true);

  useEffect(() => {
    const badImg = document.querySelector('.studentImage');

    badImg?.addEventListener('error', () => {
      setIsImg(false);
    });
  }, [imgSrc]);

  return (
    <Box marginTop={4}>
      {isImg ? (
        <StudentCardImage className="studentImage" src={imgSrc} />
      ) : (
        <>
          <object data={imgSrc} height="800" width="800" type="application/pdf" />
          <p>
            <a href={imgSrc} target="_blank" rel="noreferrer">
              別タブ表示
            </a>
          </p>
        </>
      )}
    </Box>
  );
};

StudentCard.displayName = 'StudentCard';

const StudentCardImage = styled.img`
  height: 450px;
`;
