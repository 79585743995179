import { useCallback, useState } from 'react';
import { Http } from '../api/axios';

export const useFetchStudentCount = () => {
  const [isloading, setisLoading] = useState(false);
  const [studentCount, setStudentCount] = useState(0);

  const fetchStudentCount = useCallback(async () => {
    setisLoading(true);
    const res = await Http.get('/api/studentList/countAllStudents');
    setStudentCount(res.data);
    setisLoading(false);
  }, []);

  return { fetchStudentCount, studentCount, isloading };
};
