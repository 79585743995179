import React, { useState, useCallback, VFC } from 'react';

type Props = {
  studentName: string;
  studentId: number;
  openDialog: (studentId: number, studentName: string) => void;
};
export const DisplayDialogLink: VFC<Props> = function ({ studentName, studentId, openDialog }) {
  const [className, setClassName] = useState<string>('');

  const open = useCallback(() => {
    openDialog(studentId, studentName);
    // 名前リンクをクリックしたらわかりやすいように文字色を変更する。
    // 画面遷移とリロードで文字色は元どおりになるが、最低限一覧画面内で区別がつけば良いものとする。
    setClassName('clicked');
  }, []);

  return (
    <a onClick={open} className={className}>
      {studentName}
    </a>
  );
};
