import React, { memo, useContext, VFC } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { DefaultButton } from '../buttons/defaultButton';
import styled from 'styled-components';
import { Controller, useForm } from 'react-hook-form';
import { getCourseMenuItems } from '../selectMenuItems';
import { DefaultSelectBox } from '../selectBox/defaultSelectBox';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Table, TableContainer } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { DefaultTitle } from '../titles/DefaultTitle';
import { studentCourse } from '../../types/studentCourse';
import { CoursesContext } from '../../providers/coursesContext';
type Props = {
  studentId: number;
  studentName: string;
  isShowCreateDialog: boolean;
  closeCreateDialog: (isShowCreateDialog: boolean) => void;
  createStudentUnlimitedCourse: (studentCourse: studentCourse) => void;
};
export const CreateStudentUnlimitedCourseDialog: VFC<Props> = memo(
  ({
    studentId,
    studentName,
    isShowCreateDialog,
    closeCreateDialog,
    createStudentUnlimitedCourse: CreateStudentUnlimitedCourse,
  }) => {
    const classes = useStyles();
    const { control, handleSubmit } = useForm<studentCourse>({
      defaultValues: {
        courseId: 1,
      },
      mode: 'onTouched',
    });
    const { courses } = useContext(CoursesContext);

    const close = () => {
      closeCreateDialog(false);
    };

    const onSubmit = handleSubmit(async (data: studentCourse) => {
      data.studentId = studentId;
      CreateStudentUnlimitedCourse(data);
      close();
    });
    return (
      <Dialog open={isShowCreateDialog} onClose={close}>
        <DialogContent>
          <form onSubmit={onSubmit}>
            <Container>
              <section>
                <DefaultTitle>受け放題コース追加</DefaultTitle>
              </section>
              <TableContainer className={classes.root}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell className={classes.head} component="th" scope="row">
                        お名前
                      </TableCell>
                      <TableCell className={classes.body} component="td" scope="row">
                        {studentName}さん
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.head} component="th" scope="row">
                        No
                      </TableCell>
                      <TableCell className={classes.body} component="td" scope="row">
                        {studentId}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.head} component="th" scope="row">
                        コース選択
                      </TableCell>
                      <TableCell className={classes.body} component="th" scope="row">
                        <Controller
                          control={control}
                          name={'courseId' as const}
                          render={({ field }) => (
                            <DefaultSelectBox menuItems={getCourseMenuItems(courses)} field={field} />
                          )}
                          rules={{ required: true }}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <DefaultButton type="submit">追加</DefaultButton>
            </Container>
          </form>
        </DialogContent>
      </Dialog>
    );
  }
);
const useStyles = makeStyles(() =>
  createStyles({
    head: {
      backgroundColor: '#2498b3',
      color: '#ffffff',
      verticalAlign: 'middle',
      textAlign: 'left',
      fontSize: '100%',
      border: '1px solid #dee2e6',
      width: '200px',
      height: '35px',
      fontWeight: 'bold',
    },
    body: {
      verticalAlign: 'middle',
      textAlign: 'left',
      fontSize: '100%',
      border: '1px solid #dee2e6',
      width: '80%',
      backgroundColor: '#ffffff',
    },
    root: {
      marginBottom: '25px',
    },
  })
);

const Container = styled.main`
  width: 80%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
`;

CreateStudentUnlimitedCourseDialog.displayName = 'CreateStudentUnlimitedCourseDialog';
