import { studentCourseDetails } from './studentCourseDetails';

export type updateStudentCourseDetails = {
  studentId: number;
  email: string;
  studentCourseDetails: studentCourseDetails[];
};

export const defaultValues = {
  studentId: 0,
  email: '',
  studentCourseDetails: [
    {
      studentId: 0,
      campaign: { studentCourseId: 0, campaignName: '' },
      course: { id: 0, courseName: '' },
      guideState: { studentCourseId: 0, guideState: '仮申し込み', canceled: false },
      studentCourse: {
        studentId: 0,
        requestOn: '',
        startOn: '',
        settlementOn: '',
        remark: '',
        courseId: 1,
        unlimitedCourseId: 1,
        availableUnlimitedCourse: false,
      },
      unlimitedCourse: {},
    },
  ],
};
