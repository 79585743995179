import format from 'date-fns/format';
import DateFnsUtils from '@date-io/date-fns';

export class ExtendDateFnsUtils extends DateFnsUtils {
  getCalendarHeaderText(date: Date): string {
    return format(date, 'yyyy MMM', { locale: this.locale });
  }

  getDatePickerHeaderText(date: Date): string {
    return format(date, 'MMMd日', { locale: this.locale });
  }
}
