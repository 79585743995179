import React, { memo, useContext, useEffect, useMemo, VFC } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { TableContainer } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import { Table } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { format } from 'date-fns';
import { isValid } from 'date-fns';
import { DefaultDatePicker } from '../../common/datepicker/defaultDatePicker';
import { student } from '../../types/student';
import { studentCourseDetails } from '../../types/studentCourseDetails';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { DefaultTextField } from '../../common/textField/defaultTextField';
import { DefaultSelectBox } from '../../common/selectBox/defaultSelectBox';
import { getCourseMenuItems, getGuideStateMenuItems } from '../../common/selectMenuItems';
import { DefaultCheckbox } from '../../common/checkbox/defaultCheckbox';
import { updateStudentCourseDetails, defaultValues } from '../../types/updateStudentCourseDetails';
import { DefaultButton } from '../../common/buttons/defaultButton';
import { CoursesContext } from '../../providers/coursesContext';
import { SelectMenuItem } from '../../types/item';

type StudentCoursesEditFormProps = {
  student: student;
  studentCourseDetails: studentCourseDetails[];
  updateStudentCourseDetails: (studentCourseDetails: updateStudentCourseDetails) => void;
};

type MenuItems = {
  course: SelectMenuItem[];
  guideState: SelectMenuItem[];
};
export const StudentCoursesEditForm: VFC<StudentCoursesEditFormProps> = memo(
  ({ student, studentCourseDetails, updateStudentCourseDetails }) => {
    const classes = useStyles();

    const {
      control,
      handleSubmit,
      setValue,
      reset,
      clearErrors,
      setError,
      formState: { errors },
    } = useForm<updateStudentCourseDetails>({
      defaultValues: defaultValues,
      mode: 'onChange',
    });
    const { fields } = useFieldArray({
      control,
      name: 'studentCourseDetails',
    });
    const { courses } = useContext(CoursesContext);

    const menuItems = useMemo((): MenuItems => {
      return {
        course: getCourseMenuItems(courses),
        guideState: getGuideStateMenuItems().slice(1),
      };
    }, []);

    useEffect(() => {
      (async () => {
        if (Object.keys(student).length !== 0 && Object.keys(studentCourseDetails).length !== 0) {
          reset({ studentId: student.id, email: student.email, studentCourseDetails: studentCourseDetails });
        }
      })();
    }, [student, studentCourseDetails]);

    const handleChangeForStartOn = (date: Date | null, arrayNumber: number) => {
      if (date === null) {
        setValue(`studentCourseDetails.${arrayNumber}.studentCourse.startOn` as const, null as never);
        setError(`studentCourseDetails.${arrayNumber}.studentCourse.startOn` as const, {
          type: 'required',
          message: '必須項目です。',
        });
        return;
      } else if (!isValid(date)) {
        setError(`studentCourseDetails.${arrayNumber}.studentCourse.startOn` as const, {
          type: 'isValid',
          message: 'この日付は無効です。',
        });
        return;
      } else {
        setValue(
          `studentCourseDetails.${arrayNumber}.studentCourse.startOn` as const,
          format(date, 'yyyy-MM-dd') as never
        );
        clearErrors(`studentCourseDetails.${arrayNumber}.studentCourse.startOn` as const);
      }
    };

    const handleChangeForSettlementOn = (date: Date | null, arrayNumber: number) => {
      if (date === null) {
        setValue(`studentCourseDetails.${arrayNumber}.studentCourse.settlementOn` as const, null as never);
      } else if (!isValid(date)) {
        setError(`studentCourseDetails.${arrayNumber}.studentCourse.settlementOn` as const, {
          type: 'isValid',
          message: 'この日付は無効です。',
        });
        return;
      } else {
        setValue(
          `studentCourseDetails.${arrayNumber}.studentCourse.settlementOn` as const,
          format(date, 'yyyy-MM-dd') as never
        );
      }
      clearErrors(`studentCourseDetails.${arrayNumber}.studentCourse.settlementOn` as const);
    };

    const handleChangeForInvitedOn = (date: Date | null, arrayNumber: number) => {
      if (date === null) {
        setValue(`studentCourseDetails.${arrayNumber}.studentCourse.invitedOn` as const, null as never);
      } else if (!isValid(date)) {
        setError(`studentCourseDetails.${arrayNumber}.studentCourse.invitedOn` as const, {
          type: 'isValid',
          message: 'この日付は無効です。',
        });
        return;
      } else {
        setValue(
          `studentCourseDetails.${arrayNumber}.studentCourse.invitedOn` as const,
          format(date, 'yyyy-MM-dd') as never
        );
      }
      clearErrors(`studentCourseDetails.${arrayNumber}.studentCourse.invitedOn` as const);
    };

    const onSubmit = handleSubmit((data) => {
      updateStudentCourseDetails(data);
    });

    return (
      <form onSubmit={onSubmit}>
        <div>
          <TableContainer className={classes.root}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.head} component="th" scope="row">
                    No
                  </TableCell>
                  <TableCell className={classes.body} component="td" scope="row">
                    {student.id}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.head} component="th" scope="row">
                    お名前
                  </TableCell>
                  <TableCell className={classes.body} component="td" scope="row">
                    {student.name}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.head} component="th" scope="row">
                    お名前(カナ)
                  </TableCell>
                  <TableCell className={classes.body} component="td" scope="row">
                    {student.kname}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.head} component="th" scope="row">
                    メールアドレス
                  </TableCell>
                  <TableCell className={classes.body} component="td" scope="row">
                    <Controller
                      control={control}
                      name="email"
                      render={({ field }) => (
                        <DefaultTextField field={field} helperText={errors.email ? '必須項目です。' : ''} />
                      )}
                      rules={{ required: true }}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          {fields.map((field, i) => (
            <TableContainer className={classes.root} key={i}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.head} component="th" scope="row">
                      受講コース
                    </TableCell>
                    <TableCell className={classes.body} component="td" scope="row">
                      <Controller
                        control={control}
                        name={`studentCourseDetails.${i}.studentCourse.courseId` as const}
                        render={({ field }) => (
                          <DefaultSelectBox menuItems={menuItems.course} field={field} styles={{ width: 40 }} />
                        )}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.head} component="th" scope="row">
                      申込日
                    </TableCell>
                    <TableCell className={classes.body} component="td" scope="row">
                      {field.studentCourse.requestOn}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.head} component="th" scope="row">
                      希望受講開始時期
                    </TableCell>
                    <TableCell className={classes.body} component="td" scope="row">
                      <Controller
                        control={control}
                        name={`studentCourseDetails.${i}.studentCourse.startOn` as const}
                        render={({ field }) => (
                          <DefaultDatePicker
                            error={errors.studentCourseDetails?.[i]?.studentCourse?.startOn ? true : false}
                            field={field}
                            helperText={errors.studentCourseDetails?.[i]?.studentCourse?.startOn?.message || ''}
                            onChange={(e) => handleChangeForStartOn(e, i)}
                          />
                        )}
                        rules={{ required: true }}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.head} component="th" scope="row">
                      決済日
                    </TableCell>
                    <TableCell className={classes.body} component="td" scope="row">
                      <Controller
                        control={control}
                        name={`studentCourseDetails.${i}.studentCourse.settlementOn` as const}
                        render={({ field }) => (
                          <DefaultDatePicker
                            error={errors.studentCourseDetails?.[i]?.studentCourse?.settlementOn ? true : false}
                            field={field}
                            helperText={errors.studentCourseDetails?.[i]?.studentCourse?.settlementOn?.message || ''}
                            onChange={(e) => handleChangeForSettlementOn(e, i)}
                          />
                        )}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.head} component="th" scope="row">
                      Slack
                      <br />
                      Discord招待日
                    </TableCell>
                    <TableCell className={classes.body} component="td" scope="row">
                      <Controller
                        control={control}
                        name={`studentCourseDetails.${i}.studentCourse.invitedOn` as const}
                        render={({ field }) => (
                          <DefaultDatePicker
                            error={errors.studentCourseDetails?.[i]?.studentCourse?.invitedOn ? true : false}
                            field={field}
                            helperText={errors.studentCourseDetails?.[i]?.studentCourse?.invitedOn?.message || ''}
                            onChange={(e) => handleChangeForInvitedOn(e, i)}
                          />
                        )}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.head} component="th" scope="row">
                      申し込み状況
                    </TableCell>
                    <TableCell className={classes.body} component="td" scope="row">
                      <Controller
                        control={control}
                        name={`studentCourseDetails.${i}.guideState.guideState` as const}
                        render={({ field }) => (
                          <DefaultSelectBox menuItems={menuItems.guideState} field={field} styles={{ width: 40 }} />
                        )}
                      />
                      キャンセル
                      <Controller
                        control={control}
                        name={`studentCourseDetails.${i}.guideState.canceled` as const}
                        render={({ field, field: { value, onChange } }) => (
                          <DefaultCheckbox checked={value} value={!field.value} handleChange={onChange} />
                        )}
                      />
                    </TableCell>
                  </TableRow>
                  {studentCourseDetails[i]?.studentCourse.unlimitedCourseId !== 1 && (
                    <TableRow>
                      <TableCell className={classes.head} component="th" scope="row">
                        受け放題
                      </TableCell>
                      <TableCell className={classes.body} component="td" scope="row">
                        {studentCourseDetails[i]?.unlimitedCourse.unlimitedCourseName}
                        <p>
                          受講可能
                          <Controller
                            control={control}
                            name={`studentCourseDetails.${i}.studentCourse.availableUnlimitedCourse` as const}
                            render={({ field, field: { value, onChange } }) => (
                              <DefaultCheckbox checked={value} value={!field.value} handleChange={onChange} />
                            )}
                          />
                        </p>
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell className={classes.head} component="th" scope="row">
                      備考欄
                    </TableCell>
                    <TableCell className={classes.body} component="td" scope="row">
                      <Controller
                        control={control}
                        name={`studentCourseDetails.${i}.studentCourse.remark` as const}
                        render={({ field }) => <DefaultTextField field={field} multiline />}
                      />
                    </TableCell>
                  </TableRow>
                  <>
                    <TableRow>
                      <TableCell className={classes.head} component="th" scope="row">
                        キャンペーン情報
                      </TableCell>
                      <TableCell className={classes.body} component="td" scope="row">
                        <Controller
                          control={control}
                          name={`studentCourseDetails.${i}.campaign.campaignName` as const}
                          render={({ field }) => <DefaultTextField field={field} />}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.head} component="th" scope="row">
                        クーポン
                      </TableCell>
                      <TableCell className={classes.body} component="td" scope="row">
                        {studentCourseDetails[i]?.coupon.couponCode}
                      </TableCell>
                    </TableRow>
                  </>
                </TableBody>
              </Table>
            </TableContainer>
          ))}
        </div>
        <DefaultButton type="submit" isDisabled={Object.keys(errors).length != 0}>
          更新する
        </DefaultButton>
      </form>
    );
  }
);

const useStyles = makeStyles(() =>
  createStyles({
    head: {
      backgroundColor: '#2498b3',
      color: '#ffffff',
      verticalAlign: 'middle',
      textAlign: 'left',
      fontSize: '100%',
      border: '1px solid #dee2e6',
      width: '200px',
      height: '35px',
      fontWeight: 'bold',
    },
    body: {
      verticalAlign: 'middle',
      textAlign: 'left',
      fontSize: '100%',
      border: '1px solid #dee2e6',
      width: '80%',
      backgroundColor: '#ffffff',
    },
    root: {
      marginBottom: '25px',
    },
  })
);
