import { couponCondition, COUPON_CONDITION } from './coupon';

export type searchCondition = {
  nameOrEmail: string;
  startYear: string;
  startMonth: string;
  guideState: string;
  courseIds: number[];
  campaignName: string;
  order: order;
  orderDirection: orderDirection;
  couponCondition: couponCondition;
  couponCode: string;
  startInvitedOn: string;
  endInvitedOn: string;
  displayUnlimitedCourse: boolean;
  isTrial: boolean;
};

export type order = 'requestOn' | 'Student.id' | 'kname' | 'invitedOn';
export type orderDirection = 'desc' | 'asc';

export const defaultValues = {
  nameOrEmail: '',
  startYear: '',
  startMonth: '',
  guideState: '',
  courseIds: [] as number[],
  campaignName: '',
  order: '' as order,
  orderDirection: '' as orderDirection,
  couponCondition: COUPON_CONDITION.BOTH,
  couponCode: '',
  startInvitedOn: '',
  endInvitedOn: '',
  displayUnlimitedCourse: true,
  isTrial: false,
};
