import React, { useEffect, useState, createContext, VFC, ReactNode } from 'react';
import { course } from '../../src/types/course';
import { Http } from '../../src/api/axios';

type Props = {
  children: ReactNode;
};
export const CoursesContext = createContext<{ courses: course[] }>({ courses: [] });
export const CoursesProvider: VFC<Props> = (props) => {
  const [courses, setCourses] = useState<course[]>([]);

  useEffect(() => {
    (async () => {
      const res = await Http.get('/api/studentList/allCourses');
      setCourses([...res.data]);
    })();
  }, []);

  return <CoursesContext.Provider value={{ courses: courses }}>{props.children}</CoursesContext.Provider>;
};
