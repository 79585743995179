import { useState } from 'react';
import firebase from '../api/firebase';
import { useHistory } from 'react-router-dom';

export const useSignedIn = () => {
  const [isSignedIn, setIsSignedIn] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isStaff, setIsStaff] = useState<boolean>(false);

  // 受講生のメールも見れるStaff
  const [isSpStaff, setIsSpStaff] = useState<boolean>(false);
  const [isEmailError, setIsEmailError] = useState<string>('');
  const [isPasswordError, setIsPasswordError] = useState<string>('');
  const history = useHistory();

  const signInWithEmailAndPassword = (email: string, password: string) => {
    setIsEmailError('');
    setIsPasswordError('');
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        history.push('/');
      })
      .catch((e) => {
        if (e.code == 'auth/user-not-found') {
          setIsEmailError('メールアドレスが違います。');
        } else if (e.code == 'auth/wrong-password') {
          setIsPasswordError('パスワードが違います。');
        }
      });
  };

  const signedIn = () => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setIsSignedIn(true);
      } else {
        setIsSignedIn(false);
      }
    });
  };

  // NOTE(@Mackie-S): 今後のプラットフォーム化の際にDBでroleを持つことにするが現状このロジックで判別する
  const checkUserRole = () => {
    firebase.auth().onAuthStateChanged((user) => {
      const staffEmails = (process.env.REACT_APP_STAFF_EMAIL || '').split(',');
      const viewableEmailStaffEmails = (process.env.REACT_APP_VIEWABLE_EMAIL_STAFF_EMAIL || '').split(',');
      if (user?.email === process.env.REACT_APP_ADMIN_EMAIL) {
        setIsAdmin(true);
        setIsStaff(false);
        setIsSpStaff(false);
      } else if (user?.email && staffEmails.includes(user.email)) {
        setIsAdmin(false);
        setIsStaff(true);
        setIsSpStaff(false);
      } else if (user?.email && viewableEmailStaffEmails.includes(user.email)) {
        setIsAdmin(false);
        setIsStaff(false);
        setIsSpStaff(true);
      } else {
        setIsAdmin(false);
        setIsStaff(false);
        setIsSpStaff(false);
      }
    });
  };

  const signOut = () => {
    sessionStorage.removeItem('searchCondition');
    setIsAdmin(false);
    firebase.auth().signOut();
  };

  return {
    signedIn,
    checkUserRole,
    signOut,
    isSignedIn,
    isAdmin,
    isStaff,
    isSpStaff,
    signInWithEmailAndPassword,
    isEmailError,
    isPasswordError,
  };
};
