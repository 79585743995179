import React, { useState, useRef, useContext, VFC } from 'react';
import { studentList } from '../../types/studentList';
import { Http } from '../../api/axios';
import { CSVLink } from 'react-csv';
import { RoleContext } from '../../providers/roleContext';
import { studentInfoForBusiness } from '../../types/studentInfoForBusiness';
import { searchCondition } from '../../types/searchCondition';
import { UseFormGetValues } from 'react-hook-form';
import { AxiosResponse } from 'axios';

type Props = {
  getValues: UseFormGetValues<searchCondition>;
};
export const CsvButton: VFC<Props> = ({ getValues }) => {
  const [csvData, setCsvData] = useState<studentList[]>([]);
  const [csvDataForBusiness, setCsvDataForBusiness] = useState<studentInfoForBusiness[]>([]);
  const csvLink = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
  const { isAdmin } = useContext(RoleContext);

  // FIXME:headerをデータで取れるようにする。
  const headers = [
    { label: '受講生No.', key: 'student.id' },
    { label: '名前', key: 'student.name' },
    { label: 'カナ', key: 'student.kname' },
    { label: 'Discord名', key: 'discordUser.name' },
    { label: 'Email', key: 'student.email' },
    { label: '申込日', key: 'studentCourseDetailsList.0.studentCourse.requestOn' },
    { label: '受講開始時期', key: 'studentCourseDetailsList.0.studentCourse.startOn' },
    { label: '決済日', key: 'studentCourseDetailsList.0.studentCourse.settlementOn' },
    { label: 'Slack・Discord招待日招待日', key: 'studentCourseDetailsList.0.studentCourse.invitedOn' },
    { label: '備考欄', key: 'studentCourseDetailsList.0.studentCourse.remark' },
    { label: 'キャンペーン', key: 'studentCourseDetailsList.0.campaign.campaignName' },
    { label: '申込み状況', key: 'studentCourseDetailsList.0.guideState.guideState' },
    { label: 'キャンセル', key: 'studentCourseDetailsList.0.guideState.canceled' },
    { label: 'コース名', key: 'studentCourseDetailsList.0.course.courseName' },
    { label: '受け放題', key: 'studentCourseDetailsList.0.unlimitedCourse.unlimitedCourseName' },
    { label: '年齢', key: 'hearing.age' },
    { label: '性別', key: 'hearing.gender' },
    { label: '経験', key: 'hearing.experience' },
    { label: '現在の状況', key: 'hearing.job' },
    { label: '知った手段', key: 'hearing.discovery' },
    { label: '知った手段：その他', key: 'hearing.otherDiscovery' },
    { label: '受講の決め手', key: 'hearing.factor' },
    { label: '受講の決め手：その他', key: 'hearing.otherFactor' },
    { label: '説明会参加', key: 'hearing.briefing' },
    { label: '希望する働き方', key: 'hopeSupport.workStyle' },
    { label: '希望勤務地', key: 'hopeSupport.workPlace' },
    { label: 'クーポン', key: 'studentCourseDetailsList.0.coupon.couponCode' },
  ];

  // FIXME:headerをデータで取れるようにする。
  const headersForBusiness = [
    { label: '受講生No.', key: 'studentId' },
    { label: '名前', key: 'name' },
    { label: 'カナ', key: 'kname' },
    { label: 'Discord名', key: 'discordUser.name' },
    { label: 'Slack・Discord招待日', key: 'invitedOn' },
    { label: '受講希望開始時期', key: 'startOn' },
    { label: 'コース名', key: 'courseName' },
    { label: '受け放題', key: 'unlimitedCourseName' },
    { label: '年齢', key: 'hearing.age' },
    { label: '性別', key: 'hearing.gender' },
    { label: '経験', key: 'hearing.experience' },
    { label: '現在の状況', key: 'hearing.job' },
    { label: '希望する働き方', key: 'hopeSupport.workStyle' },
    { label: '希望勤務地', key: 'hopeSupport.workPlace' },
  ];

  const getCsvData = async () => {
    await Http.post('/api/studentList/allInformation', getValues()).then((res: AxiosResponse<studentList[]>) => {
      setCsvData(res.data);
      const linkElement = csvLink.current;
      if (!linkElement) return;
      linkElement.link.click();
    });
  };

  const getCsvDataForBusiness = async () => {
    const searchCondition = getValues();
    searchCondition.order = 'requestOn';
    searchCondition.orderDirection = 'desc';
    // FIXME: 0件の時・エラーが発生した時にアラート表示
    await Http.post('/api/studentList/studentInfoForBusiness', searchCondition).then(
      (res: AxiosResponse<studentInfoForBusiness[]>) => {
        setCsvDataForBusiness(res.data);
        const linkElement = csvLink.current;
        if (!linkElement) return;
        linkElement.link.click();
      }
    );
  };

  return (
    <>
      <button onClick={isAdmin ? getCsvData : getCsvDataForBusiness}>CSVダウンロード</button>
      {isAdmin ? (
        <CSVLink headers={headers} data={csvData} filename="受講生情報.csv" ref={csvLink} />
      ) : (
        <CSVLink headers={headersForBusiness} data={csvDataForBusiness} filename="受講生情報.csv" ref={csvLink} />
      )}
    </>
  );
};
