import React, { useState, useEffect, memo, VFC, useMemo } from 'react';
import styled from 'styled-components';
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Box,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { DisplayDialogLink } from './displayDialogLink';
import { searchCondition, order, orderDirection } from '../../types/searchCondition';
import { StudentDetailsDialog } from '../../common/dialog/studentDetailsDialog';
import { studentInfoForBusiness } from '../../types/studentInfoForBusiness';
import { addDays, isAfter, parse, startOfMonth, addMonths } from 'date-fns';
import { isAutoTrial } from '../../utils/student';

type Props = {
  studentList: studentInfoForBusiness[];
  sortStudentList: (order: order, orderDirection: orderDirection) => void;
};

export const ListTableForBusiness: VFC<Props> = memo(({ studentList, sortStudentList }) => {
  const classes = useStyles();
  const [perNumber, setPerNumber] = useState<number>(81);
  const [isShow, setIsShow] = useState<boolean>(false);
  const [studentId, setStudentId] = useState<number>(0);
  const [studentName, setStudentName] = useState<string>('');
  const [orderDirection, setOrderDirection] = useState<orderDirection>('desc');
  const [order, setOrder] = useState<order>('invitedOn');

  useEffect(() => {
    if (sessionStorage.getItem('searchCondition')) {
      const searchCondition: searchCondition = JSON.parse(sessionStorage.getItem('searchCondition') || '');
      setOrderDirection(searchCondition.orderDirection);
      setOrder(searchCondition.order);
    }
  }, [studentList]);

  const loadList = () => {
    setPerNumber(perNumber + 81);
  };

  const loadAllList = () => {
    setPerNumber(perNumber + studentList.length);
  };

  const openDialog = (studentId: number, studentName: string) => {
    setIsShow(true);
    setStudentId(studentId);
    setStudentName(studentName);
  };

  const closeDialog = () => {
    setIsShow(false);
  };

  const sort = (orderTarget: order) => {
    let newOrderDirection: orderDirection;

    if (order == orderTarget) {
      newOrderDirection = orderDirection == 'desc' ? 'asc' : 'desc';
    } else {
      // 初回ソートは全てdescとする
      newOrderDirection = 'desc';
    }
    setOrder(orderTarget);
    setOrderDirection(newOrderDirection);
    sortStudentList(orderTarget, newOrderDirection);
  };

  const isTrial = (invitedOn: Date): boolean => {
    // トライアル期間終了の日付
    const expirationDate = addDays(invitedOn, 15);

    // トライアル期間終了の日付と現在の日付を比較
    // 第1引数の日付が第２引数の日付以降の場合trueを返す
    return isAfter(expirationDate, new Date());
  };

  const courseStatus = useMemo(
    () =>
      studentList?.map((studentInfo) => {
        // 受け放題コースによって追加されたコースの場合はトライアル表示対象外
        if (studentInfo.remark?.includes('受け放題コースでの追加')) {
          return classes.additions;
        }
        const requestOn = parse(studentInfo.requestOn, 'yyyy-MM-dd', new Date());
        // 強制トライアル期間開始日（申込日の翌々月1日から）
        const startTrialDate = startOfMonth(addMonths(requestOn, 2));
        // 強制トライアル期間終了の日付
        const expirationDate = addDays(startTrialDate, 15);

        if (studentInfo.invitedOn) {
          const invitedOn = parse(studentInfo.invitedOn, 'yyyy-MM-dd', new Date());
          if (isAfter(invitedOn, expirationDate)) {
            // Slack・Discord参加日が強制トライアル期間後の場合、トライアル期間終了
            return classes.root;
          } else if (isAfter(startTrialDate, invitedOn)) {
            // Slack・Discord参加日が強制トライアル期間前の場合、現在の日付とトライアル期間終了日を比較
            return isTrial(invitedOn) ? classes.trial : classes.root;
          } else {
            // Slack・Discord参加日が強制トライアル期間中の場合、現在の日付と強制トライアル期間を比較
            return isAutoTrial(startTrialDate, expirationDate) ? classes.trial : classes.root;
          }
        } else {
          return isAutoTrial(startTrialDate, expirationDate) ? classes.trial : classes.root;
        }
      }),
    [studentList]
  );

  return (
    <StudentBox>
      <StudentBoxOption>
        <DisplayConditionRightBox>
          <Box display="flex">
            <Square color="#e6c573" />
            トライアル期間
          </Box>
          <Box display="flex">
            <Square color="#AFCDE9" />
            追加コース(受け放題)
          </Box>
        </DisplayConditionRightBox>
      </StudentBoxOption>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCellOfHead onClick={() => sort('Student.id')}>
                No
                {order == 'Student.id' && (
                  <Icon className={orderDirection == 'desc' ? 'fas fa-arrow-down' : 'fas fa-arrow-up'}></Icon>
                )}
              </TableCellOfHead>
              <TableCellOfHead onClick={() => sort('invitedOn')}>
                Slack
                <br />
                Discord招待日
                {order == 'invitedOn' && (
                  <Icon className={orderDirection == 'desc' ? 'fas fa-arrow-down' : 'fas fa-arrow-up'}></Icon>
                )}
              </TableCellOfHead>
              <TableCellOfHead onClick={() => sort('kname')}>
                お名前
                {order == 'kname' && (
                  <Icon className={orderDirection == 'desc' ? 'fas fa-arrow-down' : 'fas fa-arrow-up'}></Icon>
                )}
              </TableCellOfHead>
              <TableCell>Discord名</TableCell>
              <TableCell>DiscordID</TableCell>
              <TableCell>
                受講<br></br>コース
              </TableCell>
            </TableRow>
          </TableHead>
          {isShow && (
            <StudentDetailsDialog
              isShow={isShow}
              closeDialog={closeDialog}
              studentId={studentId}
              studentName={studentName}
            />
          )}
          <TableBody>
            {studentList.slice(0, perNumber).map((student, i) => (
              <TableRow key={i + student.name + student.studentId} className={courseStatus[i]}>
                <TableCell component="th" scope="row">
                  {student.studentId}
                </TableCell>
                <TableCell component="th" scope="row">
                  {student.invitedOn}
                </TableCell>
                <TableCell component="th" scope="row">
                  <DisplayDialogLink studentName={student.name} studentId={student.studentId} openDialog={openDialog} />
                </TableCell>
                <TableCell component="th" scope="row">
                  {student.discordUser.name}
                </TableCell>
                <TableCell component="th" scope="row">
                  {student.discordUser.discordId}
                </TableCell>
                <TableCell component="th" scope="row">
                  <p>{student.courseName}</p>
                  {student.unlimitedCourseName}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {studentList.length > perNumber ? <DisplayMore onClick={loadList}>さらに表示する</DisplayMore> : ''}
      {studentList.length > perNumber ? <DisplayMore onClick={loadAllList}>全件表示する</DisplayMore> : ''}
    </StudentBox>
  );
});

const StudentBox = styled.div`
  overflow: scroll;
  padding: 30px;
  background-color: #ffffff;
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.1);
`;

const StudentBoxOption = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DisplayConditionRightBox = styled.div`
  display: flex;
  padding-top: 7px;
  gap: 24px;
`;

const Square = styled.span<{ color: string }>`
  width: 20px;
  height: 20px;
  ${({ color }) => `background: ${color}`};
  margin-right: 4px;
`;

const DisplayMore = styled.a`
  margin-right: 45px;
  cursor: pointer;
  color: #2498b3;
`;

const Icon = styled.i`
  bottom: 10px;
  right: 3px;
  position: absolute;
`;

const TableCellOfHead = styled(TableCell)`
  position: relative;
  cursor: pointer;

  &:hover {
    background-color: #196a7d;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  }
`;

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: '#f2f2f2',
      },
    },
    trial: {
      backgroundColor: '#e6c573',
    },
    additions: {
      backgroundColor: '#AFCDE9',
    },
    labelRoot: {
      marginLeft: '16px',
    },
  })
);

ListTableForBusiness.displayName = 'ListTableForBusiness';
