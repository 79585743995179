import React, { useState, createContext, useCallback, useEffect, useContext, VFC } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { StudentCoursesEditForm } from './studentCoursesEditForm';
import { updateStudentCourseDetails } from '../../types/updateStudentCourseDetails';
import { Http } from '../../api/axios';
import { Loading } from '../../common/loading/loading';
import { DefaultTitle } from '../../common/titles/DefaultTitle';
import { DefaultAlert } from '../../common/alert/defaultAlert';
import { RoleContext } from '../../providers/roleContext';
import { useFetchStudentDetails } from '../../hooks/useFetchStudentDetails';
import { StudentCard } from '../../common/studentCard';
import { StudentCourses } from './studentCourses';

export const StudentIdContext = createContext<{ studentId: string }>({ studentId: '' });

export const StudentDetailsEditPage: VFC = () => {
  const {
    fetchStudentDetails,
    fetchStudentCard,
    student,
    studentCourseDetails,
    studentCard,
  } = useFetchStudentDetails();

  const { studentId } = useParams<{ studentId: string }>();
  const [isShowAlert, setIsShowAlert] = useState<boolean>();
  const [isSuccess, setIsSuccess] = useState<boolean>();
  const [message, setMessage] = useState<string>('');
  const [loadingFlag, setLoadingFlag] = useState<boolean>(false);
  const { isAdmin, isStaff } = useContext(RoleContext);

  useEffect(() => {
    (async () => {
      await fetchStudentDetails(parseInt(studentId), isAdmin, isStaff);
    })();
  }, [studentId]);

  if (isAdmin) {
    useEffect(() => {
      (async () => {
        const requestForStudentDiscount = studentCourseDetails.find(({ campaign }) => campaign.campaignName === '学割');
        if (requestForStudentDiscount) {
          await fetchStudentCard(requestForStudentDiscount.studentCourse.id);
        }
      })();
    }, [studentCourseDetails]);
  }

  useEffect(() => {
    window.scroll({ top: 0 });
  }, []);

  const updateStudentCourseDetails = useCallback(async (studentCourseDetails: updateStudentCourseDetails) => {
    setLoadingFlag(true);
    await Http.post(`/api/${studentId}/studentCourseDetails/update`, studentCourseDetails)
      .then(() => {
        setIsSuccess(true);
        setMessage('更新が完了しました。');
      })
      .catch(() => {
        setIsSuccess(false);
        setMessage('更新が失敗しました。');
      });
    setIsShowAlert(true);
    setTimeout(function () {
      setIsShowAlert(false);
    }, 2000);
    setLoadingFlag(false);
  }, []);

  return (
    <div>
      {loadingFlag && <Loading></Loading>}
      {isShowAlert && <DefaultAlert severity={isSuccess ? 'success' : 'error'}>{message}</DefaultAlert>}
      <Container>
        <BackIndex>
          <Link to="/">
            受講者一覧ページ<i className="fas fa-caret-left"></i>
          </Link>
        </BackIndex>
        <section>
          <DefaultTitle>受講者情報詳細ページ</DefaultTitle>
        </section>
        <div className="innerBox">
          <StudentCourseDetailsBox>
            {isAdmin ? (
              <StudentCoursesEditForm
                student={student}
                studentCourseDetails={studentCourseDetails}
                updateStudentCourseDetails={updateStudentCourseDetails}
              />
            ) : (
              <StudentCourses student={student} studentCourseDetails={studentCourseDetails} />
            )}
          </StudentCourseDetailsBox>
        </div>
        {isAdmin && studentCard && <StudentCard imgSrc={studentCard} />}
      </Container>
    </div>
  );
};

const Container = styled.main`
  width: 80%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
`;

const BackIndex = styled.div`
  width: 100%;
  padding-top: 10px;
  > a {
    color: #2498b3;
  }
`;

const StudentCourseDetailsBox = styled.section`
  margin: auto;
  text-align: center;
`;
