import React, { memo, VFC } from 'react';
import { ExtendDateFnsUtils } from '../../api/extendDateFnsUtils';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import jaLocale from 'date-fns/locale/ja';
import { ControllerRenderProps } from 'react-hook-form';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';

type Props = {
  field: ControllerRenderProps<any, string>;
  helperText: string;
  error: boolean;
  label?: string;
  onChange: (e: any) => void;
  maxDate?: Date;
  minDate?: Date;
};
export const DefaultDatePicker: VFC<Props> = memo((props) => {
  return (
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={ExtendDateFnsUtils} locale={jaLocale}>
        <KeyboardDatePicker
          {...props.field}
          helperText={props.helperText}
          error={props.error}
          label={props.label}
          format="yyyy/MM/dd"
          margin="normal"
          maxDate={props.maxDate && props.maxDate}
          minDate={props.minDate && props.minDate}
          value={props.field.value ? props.field.value : null}
          onChange={props.onChange}
        />
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
});

const theme = createMuiTheme({
  overrides: {
    MuiInputBase: {
      root: {
        backgroundColor: '#ffffff',
      },
    },
  },
  palette: {
    primary: {
      main: '#2498b3',
    },
  },
});

DefaultDatePicker.displayName = 'DefaultDatePicker';
