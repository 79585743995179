import React, { VFC, memo } from 'react';
import styled from 'styled-components';
import { device } from '../../style/device';
import { DefaultButton } from '../../common/buttons/defaultButton';
import { Controller, useForm } from 'react-hook-form';
import { DefaultTextField } from '../../common/textField/defaultTextField';

type Props = {
  handleSubmitForSignIn: (email: string) => void;
};
export const SignIn: VFC<Props> = memo(({ handleSubmitForSignIn }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
    },
    mode: 'onTouched',
  });

  const onSubmit = handleSubmit(async (data) => {
    handleSubmitForSignIn(data.email);
  });

  return (
    <form onSubmit={onSubmit}>
      <Dl>
        <Dt>お申し込み時に入力したメールアドレスを入力してください(※必須)</Dt>
        <Dd>
          <Controller
            control={control}
            name="email"
            render={({ field }) => (
              <DefaultTextField
                type="email"
                helperText={errors.email ? '必須入力です。' : ''}
                label="メールアドレス"
                field={field}
              />
            )}
            rules={{ required: true }}
          />
        </Dd>
      </Dl>
      <SignInFormBoxButton>
        <DefaultButton type="submit" width={35} isDisabled={errors.email ? true : false}>
          送信する
        </DefaultButton>
      </SignInFormBoxButton>
    </form>
  );
});

const Dl = styled.dl`
  padding-bottom: 30px;
`;
const Dt = styled.dt`
  background-color: #2498b3;
  color: #fff;
  padding: 20px;
  font-weight: 700;

  @media ${device.mobile} {
    padding: 10px;
  }
`;
const Dd = styled.dd`
  background-color: #fff;
  padding: 20px;
  margin-bottom: 0px;
  margin-left: 0;
  white-space: break-spaces;
  line-height: initial;
`;

const SignInFormBoxButton = styled.div`
  text-align: center;
`;
