import React, { memo, VFC } from 'react';
import Alert from '@material-ui/lab/Alert';
import { createStyles, makeStyles } from '@material-ui/core';

type Props = {
  severity: 'error' | 'success' | 'info' | 'warning' | undefined;
  children: string;
};
export const DefaultAlert: VFC<Props> = memo((props) => {
  const classes = useStyles();
  return (
    <Alert className={classes.root} severity={props.severity}>
      {props.children}
    </Alert>
  );
});

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      position: 'fixed',
      top: 0,
      boxSizing: 'border-box',
      width: '100%',
      zIndex: 50,
    },
  })
);

DefaultAlert.displayName = 'DefaultAlert';
