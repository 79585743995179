import { useCallback, useState, useContext } from 'react';
import { Http } from '../api/axios';
import { getMonth, getYear, subMonths } from 'date-fns';
import { CoursesContext } from '../providers/coursesContext';
import { applicationStatus } from '../types/applicationStatus';

type applicationStatusThisMonth = {
  [key: string]: number[];
};
export const useFetchApplicationStatus = () => {
  const { courses } = useContext(CoursesContext);
  const [applicationStatusThisMonth, setApplicationStatusThisMonth] = useState({} as applicationStatusThisMonth);
  // 単体コースは除く
  const removedCourses = [1, 3];

  const today = new Date();
  const lastMonth = subMonths(today, 1);
  const monthBeforeLast = subMonths(today, 2);
  const [numberOfApplicationsLastMonth, setNumberOfApplicationsLastMonth] = useState({
    month: getMonth(lastMonth) + 1,
    count: 0,
  });
  const [numberOfApplicationsMonthBeforeLast, setNnumberOfApplicationsMonthBeforeLast] = useState({
    month: getMonth(monthBeforeLast) + 1,
    count: 0,
  });

  const getNumberOfApplications = useCallback((date: Date) => {
    return Http.get('/api/studentList/numberOfApplications', {
      params: { year: getYear(date), month: getMonth(date) + 1 },
    });
  }, []);

  const fetchApplicationStatus = async () => {
    const res = await Http.get('/api/studentList/applicationStatus', {
      params: { year: getYear(today), month: getMonth(today) + 1 },
    });

    // 最終的に{案内状況:[コースの人数]}の形式となる
    // 例){"仮申し込み":[0,1,3,...], "本申し込み":[0,0,0,...],...}
    const newApplicationStatusThisMonth: applicationStatusThisMonth = res.data.reduce(
      (result: applicationStatusThisMonth, current: applicationStatus) => {
        if (removedCourses.includes(current.courseId)) {
          return result;
        }
        if (current.guideState in result) {
          result[current.guideState][current.courseId - 1] = current.count;
        } else {
          // コースの数分、0で配列を満たしておく
          const listOfPeople: number[] = Array(courses.length).fill(0);
          listOfPeople[current.courseId - 1] = current.count;

          result = { ...result, [current.guideState]: [...listOfPeople] };
        }
        return result;
      },
      {}
    );

    setApplicationStatusThisMonth({ ...newApplicationStatusThisMonth });
    fetchApplicationStatusLastMonth();
    fetchApplicationStatusMonthBeforeLast();
  };

  const fetchApplicationStatusLastMonth = async () => {
    const res = await getNumberOfApplications(lastMonth);
    numberOfApplicationsLastMonth.count = res.data;
    setNumberOfApplicationsLastMonth({ ...numberOfApplicationsLastMonth });
  };

  const fetchApplicationStatusMonthBeforeLast = async () => {
    const res = await getNumberOfApplications(monthBeforeLast);
    numberOfApplicationsMonthBeforeLast.count = res.data;
    setNnumberOfApplicationsMonthBeforeLast({ ...numberOfApplicationsMonthBeforeLast });
  };

  return {
    fetchApplicationStatus,
    applicationStatusThisMonth,
    numberOfApplicationsMonthBeforeLast,
    numberOfApplicationsLastMonth,
  };
};
