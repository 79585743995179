import { hearing } from '../types/hearing';
import { hopeSupport } from '../types/hopeSupport';

export type hearingDetails = {
  hearing: hearing;
  hopeSupport: hopeSupport;
};

export const defaultValues = {
  hearing: {
    age: 0,
    gender: '',
    experience: '',
    job: '',
    discovery: '',
    otherDiscovery: '',
    factor: '',
    otherFactor: '',
    briefing: false,
  },
  hopeSupport: { studentId: 0, workStyle: '', workPlace: '' },
};
