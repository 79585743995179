import { useCallback, useState } from 'react';
import { Http } from '../api/axios';
import { studentDetails } from '../types/studentDetails';
import { student } from '../types/student';
import { studentCourseDetails } from '../types/studentCourseDetails';
import { discordUser } from '../types/discordUser';

type studentCourseDetailsForBusiness = {
  student: student;
  discordUser: discordUser;
  studentCourseDetailsList: studentCourseDetails[];
};
export const useFetchStudentDetails = () => {
  const [student, setStudent] = useState<student>({} as student);
  const [studentCourseDetails, setStudentCourseDetails] = useState([] as studentCourseDetails[]);
  const [studentCard, setStudentCard] = useState<string>('');

  const fetchStudentDetails = useCallback(async (studentId: number, isAdmin?: boolean, isStaff?: boolean) => {
    if (isAdmin || isStaff) {
      const res = await Http.get(`/api/${studentId}/studentDetails`);
      const studentDetails: studentDetails[] = res.data;

      const studentCourseDetailsList = studentDetails.map((array) => {
        return array.studentCourseDetailsList[0];
      });
      setStudentCourseDetails(studentCourseDetailsList);
      setStudent(studentDetails[0].student);
    } else {
      const res = await Http.get(`/api/${studentId}/studentDetailsForBusiness`);
      const studentDetails: studentCourseDetailsForBusiness = res.data;
      setStudentCourseDetails(studentDetails.studentCourseDetailsList);
      setStudent(studentDetails.student);
    }
  }, []);

  const fetchStudentCard = useCallback(async (studentCourseId: number) => {
    const res = await Http.get(`/api/${studentCourseId}/studentCard`);
    setStudentCard(res.data);
  }, []);

  return { fetchStudentDetails, fetchStudentCard, student, studentCourseDetails, studentCard };
};
