import React, { VFC } from 'react';
import styled from 'styled-components';
import { device } from '../../style/device';

export const PageTop: VFC = () => {
  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <StyledPageTop>
      <p>
        <a onClick={scrollTop}>PAGE TOP</a>
      </p>
    </StyledPageTop>
  );
};

const StyledPageTop = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: 77%;

  & a {
    background: #666;
    text-decoration: none;
    color: #fff;
    width: 100px;
    padding: 30px 0;
    text-align: center;
    display: block;
    border-radius: 5px;

    @media ${device.mobile} {
      width: 80px;
    }
  }

  & a:hover {
    text-decoration: none;
    background: #999;
  }
`;
