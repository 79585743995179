import React, { useCallback, useState, VFC } from 'react';
import styled from 'styled-components';
import { device } from '../../style/device';
import { Http } from '../../api/axios';
import { SignIn } from './signIn';
import { Form } from './form';
import { Complete } from './complete';
import { student } from '../../types/student';
import { hearingDetails } from '../../types/hearingDetails';
import { DefaultTitle } from '../../common/titles/DefaultTitle';
import { DefaultAlert } from '../../common/alert/defaultAlert';

export const HearingAnswersPage: VFC = () => {
  const [title, setTitle] = useState<string>('ヒアリングフォームサインイン');
  const [signedIn, setSignedIn] = useState<boolean>(false);
  const [student, setStudent] = useState<student>();
  const [answered, setAnswered] = useState<boolean>(false);
  const [alertContent, setAlertContent] = useState<{
    severity: 'error' | 'success' | 'info' | 'warning' | undefined;
    message: string;
  }>({
    severity: undefined,
    message: '',
  });

  const handleSubmitForSignIn = useCallback(async (email: string) => {
    try {
      const res = await Http.get('/api/hearing/getStudent', { params: { email: email } });
      setSignedIn(true);
      setStudent(res.data);
      setTitle(`${res.data.name}さんサインフォーム`);
    } catch (err) {
      if (err.response.status === 303) {
        setTitle('ヒアリング回答完了');
        setAlertContent({ severity: 'success', message: '既に回答済です。' });
        setAnswered(true);
      } else if (err.response.status === 400) {
        setAlertWithTimeout(
          'お申し込みいただいていないか、お申し込み時のメールアドレスと異なります。お申し込み時のメールアドレスをご確認ください。'
        );
      } else {
        setAlertContent({
          severity: 'error',
          message:
            'システムエラーが発生いたしました。大変ご迷惑をおかけして申し訳ございません。お手数ですが、運営までご連絡ください。',
        });
      }
    }
  }, []);

  const answerCompleted = useCallback(async (hearingDetails: hearingDetails) => {
    await Http.post('/api/hearing/answered', hearingDetails)
      .then(() => {
        setAnswered(true);
        setTitle('ヒアリング回答完了');
      })
      .catch(() => {
        // FIXME: エラー画面を用意する。
        setAlertContent({
          severity: 'error',
          message:
            'システムエラーが発生いたしました。大変ご迷惑をおかけして申し訳ございません。お手数ですが、運営までご連絡ください。',
        });
        alert(
          'システムエラーが発生いたしました。大変ご迷惑をおかけして申し訳ございません。お手数ですが、運営までご連絡ください。'
        );
      });
  }, []);

  const setAlertWithTimeout = useCallback((message: string) => {
    setAlertContent({
      severity: 'error',
      message: message,
    });
    setTimeout(function () {
      setAlertContent({
        severity: 'error',
        message: '',
      });
    }, 5000);
  }, []);

  return (
    <>
      {alertContent.message.length > 0 && (
        <DefaultAlert severity={alertContent.severity}>{alertContent.message}</DefaultAlert>
      )}
      <Container>
        <h1>
          <Logo src={`${process.env.PUBLIC_URL}/assets/logo-color.png`} alt="ロゴ" />
        </h1>
        <Section>
          <DefaultTitle>{title}</DefaultTitle>
        </Section>
        {answered && <Complete />}
        {!answered && (
          <div className="innerBox">
            {!signedIn && <SignIn handleSubmitForSignIn={handleSubmitForSignIn} />}
            {signedIn && student && (
              <Form student={student} completed={answerCompleted} setAlert={setAlertWithTimeout} />
            )}
          </div>
        )}
      </Container>
    </>
  );
};

const Logo = styled.img`
  height: 40px;
  display: block;
  margin: auto;
  padding: 20px 0px;
`;

const Container = styled.main`
  width: 80%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  padding: 50px 0;

  @media screen and ${device.mobile} {
    width: 90%;
    padding: 80px 0;
  }
`;

const Section = styled.section`
  @media screen and ${device.mobile} {
    margin: 40px;
  }
`;
