import React, { useEffect, useState, useContext, useCallback, VFC } from 'react';
import styled from 'styled-components';
import { Http } from '../../api/axios';
import { SearchForm } from './searchForm';
import { ListTable } from './listTable';
import { ApplicationStatusThisMonth } from './applicationStatusThisMonth';
import { bulkUpdateStudentList } from '../../types/bulkUpdateStudentList';
import { Loading } from '../../common/loading/loading';
import { UpdateContainer } from './updateContainer';
import { guideState } from '../../types/guideState';
import { useFetchStudentList } from '../../hooks/useFetchStudentList';
import { useFetchApplicationStatus } from '../../hooks/useFetchApplicationStatus';
import { searchCondition, order, orderDirection, defaultValues } from '../../types/searchCondition';
import { DefaultTitle } from '../../common/titles/DefaultTitle';
import { DefaultAlert } from '../../common/alert/defaultAlert';
import { studentCourse } from '../../types/studentCourse';
import { ListTableForBusiness } from './listTableForBusiness';
import { RoleContext } from '../../providers/roleContext';
import { useFetchStudentCount } from '../../hooks/useFetchStudentCount';
import { Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';

export const StudentListPage: VFC = () => {
  const { fetchStudentList, loadingFlag, studentList, studentListForBusiness, trialStudents } = useFetchStudentList();
  const {
    fetchApplicationStatus,
    applicationStatusThisMonth,
    numberOfApplicationsMonthBeforeLast,
    numberOfApplicationsLastMonth,
  } = useFetchApplicationStatus();
  const { isAdmin, isSpStaff } = useContext(RoleContext);
  const [isShowAlert, setIsShowAlert] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [bulkUpdateStudentList, setBulkUpdateStudentList] = useState<bulkUpdateStudentList>({
    guideStateList: [],
    studentCourseList: [],
  });
  const [isShow, setIsShow] = useState<boolean>(false);

  // 受講者総数
  const { fetchStudentCount, studentCount } = useFetchStudentCount();
  useEffect(() => {
    fetchStudentCount();
  }, [fetchStudentCount]);

  useEffect(() => {
    (async () => {
      const searchCondition = sessionStorage.getItem('searchCondition')
        ? JSON.parse(sessionStorage.getItem('searchCondition') || '')
        : defaultValues;
      await fetchStudentList(searchCondition, isAdmin, isSpStaff);
      fetchApplicationStatus();
    })();
  }, []);

  const addUpdateGuideStateList = useCallback((updateTarget: guideState) => {
    // 既に更新対象が追加されている場合は追加しない
    if (bulkUpdateStudentList?.guideStateList.find((list) => list.studentCourseId === updateTarget.studentCourseId)) {
      return;
    }
    const newUpdateGuideStateList = [...bulkUpdateStudentList.guideStateList, updateTarget];
    bulkUpdateStudentList.guideStateList = newUpdateGuideStateList;
    setBulkUpdateStudentList(bulkUpdateStudentList);
  }, []);

  const addUpdateStudentCourseList = useCallback((updateTarget: studentCourse) => {
    // 既に更新対象が追加されている場合は追加しない
    if (bulkUpdateStudentList?.studentCourseList.find((list) => list.id === updateTarget.id)) {
      return;
    }

    const newUpdateStudentCourseList = [...bulkUpdateStudentList.studentCourseList, updateTarget];
    bulkUpdateStudentList.studentCourseList = newUpdateStudentCourseList;
    setBulkUpdateStudentList(bulkUpdateStudentList);
  }, []);

  const handleSubmit = useCallback(async (searchCondition: searchCondition) => {
    await fetchStudentList(searchCondition, isAdmin, isSpStaff);
  }, []);

  const sortStudentList = async (order: order, orderDirection: orderDirection) => {
    const searchCondition = sessionStorage.getItem('searchCondition')
      ? JSON.parse(sessionStorage.getItem('searchCondition') || '')
      : defaultValues;
    searchCondition.order = order;
    searchCondition.orderDirection = orderDirection;

    await fetchStudentList(searchCondition, isAdmin, isSpStaff);
  };

  const updateStudentList = useCallback(async () => {
    await Http.post('/api/studentList/bulkUpdate', bulkUpdateStudentList).then(() => {
      bulkUpdateStudentList.guideStateList = [];
      bulkUpdateStudentList.studentCourseList = [];
      setBulkUpdateStudentList(bulkUpdateStudentList);
    });
    const searchCondition = sessionStorage.getItem('searchCondition')
      ? JSON.parse(sessionStorage.getItem('searchCondition') || '')
      : defaultValues;
    await handleSubmit(searchCondition);
    fetchApplicationStatus();
    setIsShowAlert(true);
    setIsSuccess(true);
    setMessage('更新が完了しました。');
    setTimeout(function () {
      setIsShowAlert(false);
    }, 6000);
  }, []);

  const deleteStudentCourseDetails = useCallback(async (studentCourseId: number) => {
    await Http.get(`/api/studentList/delete/${studentCourseId}`);
    const searchCondition = sessionStorage.getItem('searchCondition')
      ? JSON.parse(sessionStorage.getItem('searchCondition') || '')
      : defaultValues;
    await handleSubmit(searchCondition);
    fetchApplicationStatus();
    setIsShowAlert(true);
    setIsSuccess(true);
    setMessage('削除が完了しました。');
    setTimeout(function () {
      setIsShowAlert(false);
    }, 6000);
  }, []);

  const handleChange = () => {
    setIsShow(!isShow);
  };
  const createStudentUnlimitedCourse = useCallback(async (studentCourseCreate: studentCourse) => {
    await Http.post(`/api/studentList/add/studentCourse`, studentCourseCreate)
      .then(async () => {
        const searchCondition = sessionStorage.getItem('searchCondition')
          ? JSON.parse(sessionStorage.getItem('searchCondition') || '')
          : defaultValues;
        await handleSubmit(searchCondition);
        fetchApplicationStatus();
        setIsShowAlert(true);
        setIsSuccess(true);
        setMessage('新規受講コース登録が完了しました。');
        setTimeout(function () {
          setIsShowAlert(false);
        }, 6000);
      })
      .catch(() => {
        setIsShowAlert(true);
        setIsSuccess(false);
        setMessage('登録済のコースです。');
        setTimeout(function () {
          setIsShowAlert(false);
        }, 6000);
      });
  }, []);
  const {
    handleSubmit: RHFhandleSubmit,
    getValues,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm<searchCondition>({
    defaultValues: defaultValues,
  });

  return (
    <div>
      {loadingFlag && <Loading />}
      {isShowAlert && <DefaultAlert severity={isSuccess ? 'success' : 'error'}>{message}</DefaultAlert>}
      <Container>
        {isAdmin && (
          <ApplicationSection>
            <DefaultTitle>
              今月の申し込み状況
              <Icon className={isShow ? 'fas fa-angle-down' : 'fas fa-angle-up'} onClick={handleChange}></Icon>
            </DefaultTitle>
            {isShow && (
              <div className="innerBoxForTable">
                <ApplicationStatusThisMonth
                  applicationStatusThisMonth={applicationStatusThisMonth}
                  numberOfApplicationsLastMonth={numberOfApplicationsLastMonth}
                  numberOfApplicationsMonthBeforeLast={numberOfApplicationsMonthBeforeLast}
                />
              </div>
            )}
          </ApplicationSection>
        )}
        <SearchFormBoxSection>
          <DefaultTitle>受講者検索フォーム</DefaultTitle>
          <div id="innerSearchBox" className="innerBox">
            <SearchForm
              search={handleSubmit}
              RHFhandleSubmit={RHFhandleSubmit}
              getValues={getValues}
              setValue={setValue}
              control={control}
              reset={reset}
              errors={errors}
            />
          </div>
        </SearchFormBoxSection>
        <TableSection>
          <TitleDecorator>
            <Title>受講者一覧</Title>
            <Typography>総数：{studentCount}名</Typography>
          </TitleDecorator>
          <div className="innerBoxForTable">
            {isAdmin || isSpStaff ? (
              <ListTable
                studentList={getValues('isTrial') ? trialStudents : studentList}
                addUpdateGuideStateList={addUpdateGuideStateList}
                addUpdateStudentCourseList={addUpdateStudentCourseList}
                deleteStudentCourseDetails={deleteStudentCourseDetails}
                createStudentUnlimitedCourse={createStudentUnlimitedCourse}
                sortStudentList={sortStudentList}
              />
            ) : (
              <ListTableForBusiness studentList={studentListForBusiness} sortStudentList={sortStudentList} />
            )}
          </div>
        </TableSection>
      </Container>
      {isAdmin && <UpdateContainer updateStudentList={updateStudentList}></UpdateContainer>}
    </div>
  );
};

const ApplicationSection = styled.section`
  width: 100%;
  margin: auto;
`;

const Icon = styled.i`
  margin-left: 15px;
  cursor: pointer;
  width: 32px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 50%;
  }
`;

const TableSection = styled.section`
  width: 100%;
  margin: auto;
`;

const Container = styled.main`
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
`;

const SearchFormBoxSection = styled.section`
  width: 80%;
  margin: auto;
`;

const Title = styled.h2`
  color: #131d34;
  font-weight: 900;
  position: relative;
  margin-top: 100px;
  font-size: 2rem;
  position: relative;
  padding-bottom: 0.6em;
  &:before {
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -41px;
    content: '';
    width: 40px;
    height: 4px;
    background-color: #2498b3;
    border-radius: 4px 0 0 4px;
  }
  &:after {
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-right: 1px;
    content: '';
    width: 40px;
    height: 4px;
    background-color: #a2d729;
    border-radius: 0 4px 4px 0;
  }
`;

const TitleDecorator = styled.div`
  position: relative;
  margin-bottom: 50px;
  padding-bottom: 0.6em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;
