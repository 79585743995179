import React, { memo, useCallback, useEffect, useMemo, useState, VFC } from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { device } from '../../style/device';
import { hearingDetails, defaultValues } from '../../types/hearingDetails';
import { student } from '../../types/student';
import { Confirm } from './confirm';
import { Controller, useForm } from 'react-hook-form';
import { DefaultButton } from '../../common/buttons/defaultButton';
import { getAgeMenuItems, genderMenuItems, prefecturesMenuItems } from '../../common/selectMenuItems';
import { DefaultSelectBox } from '../../common/selectBox/defaultSelectBox';
import { DefaultCheckbox } from '../../common/checkbox/defaultCheckbox';
import { DefaultRadio } from '../../common/radio/defaultRadio';
import {
  currentJobRadioItems,
  workStyleRadioItems,
  briefingRadioItems,
  experienceRadioItems,
} from '../../common/radioItems';
import { SelectMenuItem } from '../../types/item';
import { DefaultTextField } from '../../common/textField/defaultTextField';
import { discoveryItems, factorItems } from '../../common/checkboxItems';

type Props = {
  student: student;
  completed: (hearingDetails: hearingDetails) => void;
  setAlert: (message: string) => void;
};

type MenuItems = {
  age: SelectMenuItem[];
  gender: SelectMenuItem[];
  prefectures: SelectMenuItem[];
};
export const Form: VFC<Props> = memo(({ student, completed, setAlert }) => {
  const classes = useStyles();
  const [hearingDetails, setHearingDetails] = useState<hearingDetails>({} as hearingDetails);
  const [confirmation, setConfirmation] = useState<boolean>(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    setValue,
    setError,
    clearErrors,
  } = useForm({
    defaultValues: defaultValues,
    mode: 'onTouched',
  });

  const menuItems = useMemo((): MenuItems => {
    return {
      age: getAgeMenuItems(),
      gender: genderMenuItems,
      prefectures: prefecturesMenuItems,
    };
  }, []);

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const handleBeforeUnload = (event: BeforeUnloadEvent) => {
    event.preventDefault();
    event.returnValue = '';
  };

  const handleSubmitForAnswer = useCallback((hearingDetails: hearingDetails) => {
    completed(hearingDetails);
  }, []);

  const handleSubmitForBack = useCallback(() => {
    setConfirmation(false);
    window.scroll({ top: 0 });
  }, []);

  const onFormSubmit = (data: hearingDetails) => {
    setAlert('');
    const newHearingDetails = {
      hearing: { ...data.hearing, studentId: student.id },
      hopeSupport: { ...data.hopeSupport, studentId: student.id },
    };
    if (newHearingDetails.hearing.gender == '') {
      newHearingDetails.hearing.gender = '回答しない';
    }
    if (newHearingDetails.hopeSupport.workPlace == '') {
      newHearingDetails.hopeSupport.workPlace = '回答しない';
    }
    setHearingDetails(newHearingDetails);
    setConfirmation(true);
  };

  const onError = () => {
    setAlert('必須項目が未入力です。');
    window.scroll({ top: 0 });
  };

  const handleChangeForCheckBox = (addValue: string, name: 'factor' | 'discovery') => {
    const updateTarget: 'hearing.factor' | 'hearing.discovery' =
      name === 'factor' ? 'hearing.factor' : 'hearing.discovery';
    const values = getValues(updateTarget);

    let newValues;

    // 同じ項目を選択した場合は「,」区切りで配列に変換して値を削除する
    if (values.includes(addValue)) {
      newValues = values
        .split(',')
        .filter((value) => value !== addValue)
        .join();

      // その他のチェックが外れたらその他の記述欄をリセットする
      if (addValue === 'その他') {
        const updateTarget: 'hearing.otherFactor' | 'hearing.otherDiscovery' =
          name === 'factor' ? 'hearing.otherFactor' : 'hearing.otherDiscovery';
        setValue(updateTarget, '');
        clearErrors(updateTarget);
      }

      // 選択項目が0になった場合はエラー表示する
      if (newValues.length == 0) {
        setError(updateTarget, { type: 'required', message: '必須項目です。' });
      }
    } else {
      newValues = values.length > 0 ? values + ',' + addValue : addValue;
      clearErrors(updateTarget);
    }
    setValue(updateTarget, newValues);
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit, onError)}>
      {!confirmation && (
        <div>
          <Dl>
            <Dt>Q1.今の年齢と性別を教えてください。</Dt>
            <Dd>
              年齢：
              <Controller
                control={control}
                name="hearing.age"
                render={({ field }) => (
                  <DefaultSelectBox menuItems={menuItems.age} field={field} styles={{ display: 'block', height: 50 }} />
                )}
              />
            </Dd>
            <Dd>
              性別：
              <Controller
                control={control}
                name="hearing.gender"
                render={({ field }) => (
                  <DefaultSelectBox
                    menuItems={menuItems.gender}
                    field={field}
                    styles={{ display: 'block', height: 50 }}
                  />
                )}
              />
            </Dd>
          </Dl>
          <Dl>
            <Dt>Q2. IT業界の経験を教えてください。</Dt>
            <Dd>
              <Required>必須</Required>
              {errors.hearing?.experience && <Error>{errors.hearing?.experience.message}</Error>}
              <Controller
                control={control}
                name="hearing.experience"
                rules={{ required: '必須項目です。' }}
                render={({ field }) => <DefaultRadio radioItems={experienceRadioItems} field={field} />}
              />
            </Dd>
          </Dl>
          <Dl>
            <Dt>Q3.現在の状況を教えてください。</Dt>
            <Dd>
              <Required>必須</Required>
              {errors.hearing?.job && <Error>{errors.hearing?.job.message}</Error>}
              <Controller
                control={control}
                name="hearing.job"
                rules={{ required: '必須項目です。' }}
                render={({ field }) => <DefaultRadio radioItems={currentJobRadioItems} field={field} />}
              />
            </Dd>
          </Dl>
          <Dl>
            <Dt>Q4.希望する働き方・勤務地を教えてください。</Dt>
            <Dd>
              <LabelBoxWithRequired>
                働き方：
                <Required>必須</Required>
              </LabelBoxWithRequired>
              {errors.hopeSupport?.workStyle && <Error>{errors.hopeSupport?.workStyle.message}</Error>}
              <Controller
                control={control}
                name="hopeSupport.workStyle"
                rules={{ required: '必須項目です。' }}
                render={({ field }) => <DefaultRadio radioItems={workStyleRadioItems} field={field} />}
              />
            </Dd>
            <Dd>
              勤務地：
              <Controller
                control={control}
                name="hopeSupport.workPlace"
                render={({ field }) => (
                  <DefaultSelectBox
                    menuItems={menuItems.prefectures}
                    field={field}
                    styles={{ display: 'block', height: 50 }}
                  />
                )}
              />
            </Dd>
          </Dl>
          <Dl>
            <Dt>Q5.RaiseTechを知ったきっかけを教えてください。(複数選択可)</Dt>
            <Dd>
              <Required>必須</Required>
              {errors.hearing?.discovery && <Error>{errors.hearing?.discovery.message}</Error>}
              {Object.entries(discoveryItems).map(([key, value], i) => (
                <Grid key={key + i} className={classes.spacingXs3} item container>
                  <Grid item xs={12} sm={4}>
                    <Typography className={classes.root} variant="subtitle1">
                      {key}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    {value.map((item, i) => (
                      <Controller
                        key={item + i}
                        control={control}
                        name="hearing.discovery"
                        rules={{ required: '必須項目です。' }}
                        render={() => (
                          <DefaultCheckbox
                            value={item}
                            label={item}
                            checked={getValues('hearing.discovery').includes(item)}
                            handleChange={() => handleChangeForCheckBox(item, 'discovery')}
                          />
                        )}
                      />
                    ))}
                  </Grid>
                </Grid>
              ))}
            </Dd>
            {getValues('hearing.discovery').includes('その他') && (
              <Dd>
                <LabelBoxWithRequired>
                  その他を選択した方は内容を記入してください：
                  <Required>必須</Required>
                </LabelBoxWithRequired>
                <Controller
                  control={control}
                  name="hearing.otherDiscovery"
                  rules={{
                    maxLength: { value: 100, message: '100文字以内で入力してください。' },
                    required: '必須項目です。',
                  }}
                  render={({ field }) => (
                    <DefaultTextField
                      field={field}
                      multiline
                      rows={5}
                      helperText={errors.hearing?.otherDiscovery?.message}
                    />
                  )}
                />
                ※100文字以内
              </Dd>
            )}
          </Dl>
          <Dl>
            <Dt>Q6.受講の決め手を教えてください。(複数選択可)</Dt>
            <Dd>
              <Required>必須</Required>
              {errors.hearing?.factor && <Error>{errors.hearing?.factor.message}</Error>}
              <div>
                {factorItems.map((item, i) => (
                  <Controller
                    key={item + i}
                    control={control}
                    name="hearing.factor"
                    rules={{ required: '必須項目です。' }}
                    render={() => (
                      <DefaultCheckbox
                        value={item}
                        label={item}
                        checked={getValues('hearing.factor').includes(item)}
                        handleChange={() => handleChangeForCheckBox(item, 'factor')}
                      />
                    )}
                  />
                ))}
              </div>
            </Dd>
            {getValues('hearing.factor').includes('その他') && (
              <Dd>
                <LabelBoxWithRequired>
                  その他を選択した方は内容を記入してください：
                  <Required>必須</Required>
                </LabelBoxWithRequired>
                <Controller
                  control={control}
                  name="hearing.otherFactor"
                  rules={{
                    maxLength: { value: 100, message: '100文字以内で入力してください。' },
                    required: '必須項目です。',
                  }}
                  render={({ field }) => (
                    <DefaultTextField
                      field={field}
                      multiline
                      rows={5}
                      helperText={errors.hearing?.otherFactor?.message}
                    />
                  )}
                />
                ※100文字以内
              </Dd>
            )}
          </Dl>
          <Dl>
            <Dt>
              Q7.最後に、無料説明会に参加されたかどうか教えてください。動画視聴の場合も「参加した」にチェックを入れてください。
            </Dt>
            <Dd>
              <div>
                <Controller
                  control={control}
                  name="hearing.briefing"
                  render={({ field }) => <DefaultRadio radioItems={briefingRadioItems} field={field} />}
                />
              </div>
            </Dd>
          </Dl>
          <ConfirmFormBoxButton>
            <DefaultButton type="submit" isDisabled={Object.keys(errors).length != 0}>
              確認する
            </DefaultButton>
          </ConfirmFormBoxButton>
        </div>
      )}
      {confirmation && (
        <Confirm answer={handleSubmitForAnswer} back={handleSubmitForBack} hearingDetails={hearingDetails} />
      )}
    </form>
  );
});

const Dl = styled.dl`
  padding-bottom: 30px;
`;
const Dt = styled.dt`
  background-color: #2498b3;
  color: #fff;
  padding: 20px;
  font-weight: 700;
  line-height: 20px;

  @media ${device.mobile} {
    padding: 10px;
    line-height: 25px;
  }
`;
const Dd = styled.dd`
  background-color: #fff;
  padding: 20px;
  margin-bottom: 0px;
  margin-left: 0;
  white-space: break-spaces;
  line-height: initial;
  word-wrap: break-word;
`;

const LabelBoxWithRequired = styled.div`
  padding-bottom: 4px;
`;

const Required = styled.p`
  background-color: #f44336;
  color: #fff;
  padding: 2px 4px;
  font-size: 12px;
  display: inline-block;
  border-radius: 3px;
  vertical-align: text-bottom;
`;

const Error = styled.p`
  color: #f44336;
  font-size: 12px;
`;

const ConfirmFormBoxButton = styled.div`
  text-align: center;
`;

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      fontWeight: 600,
    },
    spacingXs3: {
      borderBottom: '1px solid #dee2e6',
      padding: '10px 0px',
      alignItems: 'center',
    },
  })
);
