import { COUPON_CONDITION } from '../types/coupon';
import { RadioItem } from '../types/item';

export const searchCouponRadioItems: RadioItem[] = [
  { item: '有り', value: COUPON_CONDITION.APPLICABLE },
  { item: '無し', value: COUPON_CONDITION.NOT_APPLICABLE },
  { item: '有り・無し', value: COUPON_CONDITION.BOTH },
];

export const briefingRadioItems: RadioItem[] = [
  { item: '参加した', value: true },
  { item: '参加していない', value: false },
];

export const experienceRadioItems: RadioItem[] = [
  { item: '未経験', value: '未経験' },
  { item: '経験者', value: '経験者' },
];

export const currentJobRadioItems: RadioItem[] = [
  { item: '会社員', value: '会社員' },
  { item: '自営業', value: '自営業' },
  { item: 'フリーター（学生含む）', value: 'フリーター（学生含む）' },
  { item: '主婦/主夫', value: '主婦/主夫' },
];

export const workStyleRadioItems: RadioItem[] = [
  { item: '転職', value: '転職' },
  { item: '副業', value: '副業' },
  { item: 'フリーランス', value: 'フリーランス' },
];
