import React, { createContext, useEffect, ReactNode, VFC } from 'react';
import { useSignedIn } from '../hooks/useSignedIn';

type Props = {
  children: ReactNode;
};
export const RoleContext = createContext<{ isAdmin: boolean; isStaff: boolean; isSpStaff: boolean }>({
  isAdmin: false,
  isStaff: false,
  isSpStaff: false,
});
export const RoleProvider: VFC<Props> = (props) => {
  const { checkUserRole, isAdmin, isStaff, isSpStaff } = useSignedIn();

  useEffect(() => {
    checkUserRole();
  }, []);

  return (
    <RoleContext.Provider value={{ isAdmin: isAdmin, isStaff: isStaff, isSpStaff: isSpStaff }}>
      {props.children}
    </RoleContext.Provider>
  );
};
