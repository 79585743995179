import React, { VFC, ReactNode, memo } from 'react';
import Button from '@material-ui/core/Button';
import { createMuiTheme, createStyles, makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { device } from '../../style/device';

type Props = {
  isDisabled?: boolean;
  onClick?: () => void;
  type?: 'button' | 'reset' | 'submit' | undefined;
  children: ReactNode;
  width?: number;
};
export const DefaultButton: VFC<Props> = memo((props) => {
  const classes = useStyles(props.width || 30);

  return (
    <MuiThemeProvider theme={theme}>
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        disabled={props.isDisabled || false}
        type={props.type}
        onClick={props.onClick}
      >
        {props.children}
      </Button>
    </MuiThemeProvider>
  );
});

const useStyles = makeStyles(() =>
  createStyles({
    button: (width) => ({
      width: `${width}%`,
      color: '#fff',
      fontSize: '1rem',
      [`@media ${device.mobile}`]: {
        width: '50%',
      },
    }),
  })
);

const theme = createMuiTheme({
  typography: {
    fontFamily: ['Noto Sans JP', 'sans-serif'].join(','),
    fontWeightBold: 'bold',
  },
  palette: {
    primary: {
      main: '#2498b3',
    },
  },
});

DefaultButton.displayName = 'DefaultButton';
