import React, { memo, useEffect, VFC } from 'react';
import styled from 'styled-components';
import { hearingDetails } from '../../types/hearingDetails';
import { HearingDetailsTable } from '../../common/hearingDetailsTable';
import { DefaultButton } from '../../common/buttons/defaultButton';

type Props = {
  answer: (hearingDetails: hearingDetails) => void;
  back: () => void;
  hearingDetails: hearingDetails;
};
export const Confirm: VFC<Props> = memo(({ answer, back, hearingDetails }) => {
  useEffect(() => {
    window.scroll({ top: 0 });
  }, []);

  const handleSubmitForAnswer = () => {
    answer(hearingDetails);
  };

  const handleSubmitForBack = () => {
    back();
  };

  return (
    <>
      <HearingDetailsTable hearingDetails={hearingDetails} isAdmin={true} />
      <BoxButton>
        <DefaultButton onClick={handleSubmitForAnswer}>送信する</DefaultButton>
      </BoxButton>
      <BoxButton>
        <DefaultButton onClick={handleSubmitForBack}>入力画面に戻る</DefaultButton>
      </BoxButton>
    </>
  );
});

const BoxButton = styled.div`
  text-align: center;
  margin: 20px 0px;
`;

Confirm.displayName = 'Confirm';
