import React, { VFC } from 'react';
import styled from 'styled-components';

export const Footer: VFC = () => {
  return <AppFooter></AppFooter>;
};

const AppFooter = styled.footer`
  height: 60px;
  background-color: #2498b3;
  margin-top: 100px;
`;
