import React, { VFC } from 'react';
import styled from 'styled-components';
import { useSignedIn } from '../../hooks/useSignedIn';
import { DefaultTextField } from '../../common/textField/defaultTextField';
import { DefaultButton } from '../../common/buttons/defaultButton';
import { Controller, useForm } from 'react-hook-form';

type ValuesType = {
  email: string;
  password: string;
};
export const LoginForm: VFC = () => {
  const { signInWithEmailAndPassword, isEmailError, isPasswordError } = useSignedIn();
  const { handleSubmit, control } = useForm<ValuesType>({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit = handleSubmit((data) => {
    signInWithEmailAndPassword(data.email, data.password);
  });

  return (
    <form onSubmit={onSubmit}>
      <FormLoginTitle className="login-ttl">ログイン</FormLoginTitle>
      <Label>メールアドレス</Label>
      <FormBox>
        <Controller
          control={control}
          name="email"
          render={({ field }) => <DefaultTextField type="email" helperText={isEmailError} field={field} />}
        />
      </FormBox>
      <FormBox>
        <Label>パスワード</Label>
        <Controller
          control={control}
          name="password"
          render={({ field }) => <DefaultTextField type="password" helperText={isPasswordError} field={field} />}
        />
      </FormBox>
      <ButtonContainer>
        <DefaultButton type="submit">ログイン</DefaultButton>
      </ButtonContainer>
    </form>
  );
};

const FormLoginTitle = styled.h2`
  margin-bottom: 15px;
  font-size: 2rem;
`;

const FormBox = styled.div`
  margin-bottom: 30px;
`;

const Label = styled.label`
  display: inline-block;
  margin: 0.5rem 0rem;
`;

const ButtonContainer = styled.div`
  text-align: center;
`;
