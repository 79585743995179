import React, { VFC } from 'react';
import styled from 'styled-components';

export const Complete: VFC = () => {
  return (
    <CompletedBox>
      <Heading>ご回答ありがとうございます！</Heading>
      <Content>
        <br />
        <p>
          RaiseTech受講で利用するコミュニケーションツール Discord について、<Strong>LINE</Strong>
          にてご案内させていただきます。
          <span />
        </p>
        <p>LINEにて下記合言葉 を入力・送信してください。</p>
        <Pass>てっくん</Pass>
        <ImgBox>
          <img src={`${process.env.PUBLIC_URL}/assets/line.png`} alt="line案内" width="100%" />
        </ImgBox>
      </Content>
    </CompletedBox>
  );
};

const CompletedBox = styled.div`
  line-height: initial;
  max-width: calc(1020px + 12%);
  margin: auto;
`;

const Heading = styled.p`
  text-align: center;
  font-size: 28px;
`;

const Content = styled.div`
  position: relative;
  padding: 1rem 0rem;
  font-size: 20px;
  text-align: left;
`;

const Pass = styled.h3`
  text-align: center;
  position: relative;
  margin: auto;
  margin-top: 52px;
  padding: 1rem 2rem;
  font-size: 32px;
  width: 60%;
  border: 3px solid #2498b3;
  border-radius: 0 10px 10px 10px;
  &:before {
    font-size: 15px;
    font-size: 1.5rem;
    position: absolute;
    top: -42px;
    left: -3px;
    height: 40px;
    padding: 0 1em;
    content: '合言葉';
    color: #fff;
    border-radius: 10px 10px 0 0;
    background: #2498b3;
  }
`;

const ImgBox = styled.div`
  text-align: center;
  margin: auto;
  margin-top: 52px;
  width: 50%;
`;

const Strong = styled.strong`
  font-weight: bold;
  border-bottom: 2px solid #ff3333;
`;
